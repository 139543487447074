export const DRAWER_TITLE = 'Drip Campaigns';

export const TEMPLATE_TABLE_CONTENT_TYPES = {
  EMAIL: 'email',
  SMS: 'sms',
};
export const MODES = {
  DISPLAY: 'display',
  FORM: 'form',
};

export const STATUS = {
  ARCHIVED: 'archived',
  DRAFT: 'draft',
  LIVE: 'live',
};

export const VIEWS = {
  CAMPAIGN: 'campaign',
  TEMPLATE: 'template',
};

export const ERROR_MESSAGES = {
  FETCH:
    'An unexpected error has occurred.  Drip campaign templates are unable to be managed at this time',
};

export const DRAWER_MODE_OPTIONS = {
  CAMPAIGN: 'campaign',
  EMAIL: 'email',
  SMS: 'sms',
};
