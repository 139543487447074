import React, { Component } from 'react';
import { Formik } from 'formik';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Toaster,
} from '@knockrentals/knock-react';
import * as _ from 'lodash';

import LeasingTeamsModal from './LeasingTeamsModal';
import UsersAPI from './UsersAPI';
import HoursAPI from '../Hours/HoursAPI';

import './_groupUserEditor.scss';

class GroupUserEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameAvailable: !!props.user,
      isCheckingUsernameAvailability: false,
      isFormValid: false,
      groupIntegrations: props.group ? props.group.integrations : [],
      userIntegrations: props.user?.integrations || [],
      isChangingPassword: false,
      updated_username: '',
      currentLeasingTeamIds: [props.group.leasing_team_id],
      is_hub_account: props.user ? props.user.is_hub_account : false,
    };

    this.usernameDebounce = _.debounce((username) => {
      this.setState({ isCheckingUsernameAvailability: true });
      UsersAPI.isUsernameAvailable(username)
        .then((response) => {
          if (username.length === 0 || !response.is_available) {
            this.setState({
              usernameAvailable: false,
              isCheckingUsernameAvailability: false,
            });
          } else {
            this.setState({
              usernameAvailable: response.is_available,
              isCheckingUsernameAvailability: false,
              updated_username: username,
            });
          }
        })
        .catch((e) => {
          Toaster.showToast(
            'Error checking username availability',
            2000,
            Toaster.ToastClasses.error
          );
          console.err(e);
        });
    }, 1000);
  }

  async componentDidMount() {
    if (this.props.isUserManagementEnabled === null) {
      this.props.setIsUserManagementEnabled();
    }
  }

  render() {
    return this.state.isChangingPassword
      ? this.renderUpdatePasswordModal()
      : this.renderUserEditorForm();
  }

  validate = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = 'Required';
    }

    if (!values.last_name) {
      errors.last_name = 'Required';
    }

    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    }

    if (!values.phone_number) {
      errors.phone_number = 'Required';
    } else {
      const cleaned = ('' + values.phone_number).replace(/\D/g, '');
      if (cleaned.length !== 10) {
        errors.phone_number = 'Invalid Phone Number';
      }
    }

    if (!values.timezone) {
      errors.timezone = 'Required';
    }

    if (!this.props.user && !values.password) {
      errors.password = 'Required';
    }

    if (
      !this.props.user &&
      (!values.passwordConfirm || values.passwordConfirm !== values.password)
    ) {
      errors.passwordConfirm = 'Passwords must match';
    }

    if (!this.props.user && values.password.length < 8) {
      errors.password = 'Passwords must be at least 8 characters';
    }

    this.state.userIntegrations.forEach((integration) => {
      const { mapped_agent_id, vendor_property_id } = integration;
      if (!mapped_agent_id) {
        errors[vendor_property_id] = 'Required';
      }
    });

    return errors;
  };

  validatePasswordChange = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.passwordConfirm || values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Passwords must match';
    }

    if (values.password.length < 8) {
      errors.password = 'Passwords must be at least 8 characters';
    }

    return errors;
  };

  usernameChanged = (event, formProps) => {
    const { username } = formProps.values;
    const value = event.target.value.replace(/ /g, '');

    if (username === value) {
      return this.setState({ usernameAvailable: true });
    }

    if (this.props.user && value === this.props.user.username) {
      this.setState({ usernameAvailable: true });
      formProps.setFieldValue('username', value);
      return;
    }

    formProps.setFieldValue('username', value);
    this.setState({ isCheckingUsernameAvailability: true }, () =>
      this.usernameDebounce(value)
    );
  };

  handleIntegrationChange = (event, vendor_property_id) => {
    const { userIntegrations } = this.state;
    for (let i = 0; i < userIntegrations.length; i += 1) {
      const integration = userIntegrations[i];
      if (integration.vendor_property_id === vendor_property_id) {
        integration.mapped_agent_id = event.target.value;
      }
    }
    this.setState({ userIntegrations });
  };

  renderUserEditorForm = () => {
    const { isUserManagementEnabled, user } = this.props;
    const { is_migrated: isMigrated = false } = user || {};
    const addingNewUser = !user;

    return (
      <Modal className="user-editor-container">
        <ModalHeader>
          <h2>
            {this.props.user ? (
              <span>
                Edit user
                <button
                  className="knock-react-button"
                  onClick={this.startChangePassword.bind(this)}
                  disabled={isMigrated}
                >
                  Change password
                </button>
              </span>
            ) : (
              'Add user'
            )}
          </h2>
          <div>
            <small>Fields with * are required.</small>
          </div>
        </ModalHeader>
        <Formik
          initialValues={{
            first_name: user ? user.first_name : '',
            last_name: user ? user.last_name : '',
            username: user ? user.username : '',
            email: user ? user.email : '',
            phone_number:
              user && user.phone_number
                ? user.phone_number.replace(/\W/g, '')
                : '',
            timezone: user ? user.timezone : '',
            password: '',
            passwordConfirm: '',
          }}
          validate={this.validate}
          onSubmit={this.submit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {(props) => (
            <form
              onKeyPress={this.enterSubmitForm.bind(this, props)}
              onSubmit={props.handleSubmit}
            >
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <div className={'modal-field-label'}>
                      <label htmlFor="first_name">
                        <strong>
                          First name<sup>*</sup>
                        </strong>
                      </label>
                    </div>
                    <div className={'modal-field-input'}>
                      <input
                        type="text"
                        className="knock-react-input"
                        name="first_name"
                        id="first_name"
                        onChange={props.handleChange}
                        value={props.values.first_name}
                        disabled={isMigrated}
                      />
                      {props.errors.first_name && (
                        <div className="knock-react-form-hint-error">
                          {props.errors.first_name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={'modal-field-label'}>
                      <label htmlFor="last_name">
                        <strong>
                          Last name<sup>*</sup>
                        </strong>
                      </label>
                    </div>
                    <div className={'modal-field-input'}>
                      <input
                        type="text"
                        className="knock-react-input"
                        name="last_name"
                        id="last_name"
                        onChange={props.handleChange}
                        value={props.values.last_name}
                        disabled={isMigrated}
                      />
                      {props.errors.last_name && (
                        <div className="knock-react-form-hint-error">
                          {props.errors.last_name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <div className={'modal-field-label username-status-icon'}>
                      <label htmlFor="username">
                        <strong>
                          Username<sup>*</sup>
                        </strong>
                      </label>
                      {props.errors.username && (
                        <span>{props.errors.username}</span>
                      )}
                      {this.state.isCheckingUsernameAvailability ? (
                        <span data-testid="checking">
                          <i className="fa fa-spin fa-cog" />
                        </span>
                      ) : this.state.usernameAvailable ? (
                        <span data-testid="available" className="txt-success">
                          <i className="fa fa-check-circle" />
                        </span>
                      ) : (
                        <span
                          data-testid="not-available"
                          className="txt-danger"
                        >
                          <i className="fa fa-exclamation-triangle" />
                        </span>
                      )}
                    </div>
                    <div className={'modal-field-input'}>
                      <input
                        type="text"
                        className="knock-react-input"
                        autoComplete="off"
                        name="username"
                        id="username"
                        value={props.values.username}
                        disabled={isMigrated}
                        onChange={(event) => this.usernameChanged(event, props)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={'modal-field-label'}>
                      <label htmlFor="email">
                        <strong>
                          Contact email<sup>*</sup>
                        </strong>
                      </label>
                    </div>
                    <div className={'modal-field-input'}>
                      <input
                        type="email"
                        className="knock-react-input"
                        name="email"
                        id="email"
                        value={props.values.email}
                        disabled={isMigrated}
                        onChange={props.handleChange}
                      />
                      {props.errors.email && (
                        <div className="knock-react-form-hint-error">
                          {props.errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <div className={'modal-field-label'}>
                      <label htmlFor="phone_number">
                        <strong>
                          Phone<sup>*</sup>
                        </strong>
                      </label>
                    </div>
                    <div className={'modal-field-input'}>
                      <input
                        type="tel"
                        className="knock-react-input"
                        name="phone_number"
                        id="phone_number"
                        maxLength="14"
                        value={props.values.phone_number}
                        disabled={isMigrated}
                        onChange={(event) =>
                          this.formatPhoneNumber(event, props)
                        }
                      />
                      {props.errors.phone_number && (
                        <div className="knock-react-form-hint-error">
                          {props.errors.phone_number}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={'modal-field-label'}>
                      <label htmlFor="timezone">
                        <strong>
                          Timezone<sup>*</sup>
                        </strong>
                      </label>
                    </div>
                    <select
                      name="timezone"
                      id="timezone"
                      className="modal-field-input knock-react-select"
                      onChange={props.handleChange}
                      value={props.values.timezone}
                    >
                      <option value={''}>--Select--</option>
                      {HoursAPI.Timezones.map((timezone) => (
                        <option key={timezone.id} value={timezone.id}>
                          {timezone.name}
                        </option>
                      ))}
                    </select>
                    {props.errors.timezone && (
                      <div className="knock-react-form-hint-error">
                        {props.errors.timezone}
                      </div>
                    )}
                  </div>
                </div>
                {addingNewUser && this.renderUpdatePasswordForm(props)}
                {addingNewUser && isUserManagementEnabled && (
                  <div className="divider" style={{ marginTop: '24px' }} />
                )}

                {isUserManagementEnabled && (
                  <div
                    className="knock-react-flex"
                    style={{ flexWrap: 'wrap' }}
                  >
                    <LeasingTeamsModal
                      userId={!addingNewUser && this.props.user.user_id}
                      group={this.props.group}
                      groups={this.props.groups}
                      currentLeasingTeams={this.state.currentLeasingTeams}
                      updateParentLeasingTeams={this.setLeasingTeams}
                      disabled={isMigrated}
                    />

                    {this.renderUserIntegrations(props)}
                  </div>
                )}
              </ModalContent>
              <ModalFooter>
                <button
                  type="button"
                  className="knock-react-button"
                  onClick={this.cancel}
                >
                  Cancel
                </button>
                <button
                  className="knock-react-button"
                  type="submit"
                  disabled={
                    this.props.isSaving || !this.state.usernameAvailable
                  }
                >
                  Save
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  renderUpdatePasswordForm(formProps) {
    return (
      <div>
        <div className="knock-react-flex">
          <div>
            <div className="modal-field-label">
              <label htmlFor="password">
                <strong>
                  Password<sup>*</sup>
                </strong>
              </label>
            </div>
            <div className={'modal-field-input'}>
              <div>
                <input
                  className="knock-react-input"
                  name="password"
                  id="password"
                  type="password"
                  autoComplete="new-password"
                  onChange={formProps.handleChange}
                  value={formProps.values.password}
                />
                {formProps.errors.password && (
                  <div className="knock-react-form-hint-error">
                    {formProps.errors.password}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-field-label">
              <label htmlFor="passwordConfirm">
                <strong>
                  Confirm password<sup>*</sup>
                </strong>
              </label>
            </div>
            <div className="modal-field-input">
              <div>
                <input
                  className="knock-react-input"
                  type="password"
                  autoComplete="new-password"
                  name="passwordConfirm"
                  id="passwordConfirm"
                  onChange={formProps.handleChange}
                  value={formProps.values.passwordConfirm}
                />
                {formProps.errors.passwordConfirm && (
                  <div className="knock-react-form-hint-error">
                    {formProps.errors.passwordConfirm}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUpdatePasswordModal() {
    return (
      <Modal className="user-editor-container">
        <ModalHeader>
          <h2>Change user password</h2>
        </ModalHeader>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validate={this.validatePasswordChange}
          onSubmit={this.submitPasswordUpdate}
        >
          {(props) => (
            <form autoComplete="off" onSubmit={props.handleSubmit}>
              <ModalContent>
                {this.renderUpdatePasswordForm(props)}
              </ModalContent>
              <ModalFooter>
                <button
                  type="button"
                  className="knock-react-button"
                  onClick={this.cancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="knock-react-button"
                  disabled={this.props.isSaving}
                >
                  Save
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  formatPhoneNumber = (event, formProps) => {
    const { value } = event.target;
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      const number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
      formProps.setFieldValue('phone_number', number);

      return;
    }

    formProps.setFieldValue('phone_number', value);
  };

  renderUserIntegrations = (formProps) => {
    const { groupIntegrations, userIntegrations } = this.state;

    // Add any group integrations that aren't in the user's integrations
    for (let i = 0; i < groupIntegrations.length; i += 1) {
      const { vendor, property_id, vendor_property_id, agents } =
        groupIntegrations[i];
      if (
        !userIntegrations
          .map((i) => i.vendor_property_id)
          .includes(vendor_property_id)
      ) {
        userIntegrations.push({
          mapped_agent_id: '',
          vendor,
          property_id,
          vendor_property_id,
          agents,
        });
      }
    }

    if (!userIntegrations.length) {
      return null;
    }

    const mappingUI = userIntegrations
      .filter((integration) => integration !== null)
      .map((integration) => {
        const { vendor, property_id, vendor_property_id } = integration;
        const integrationKey = `${vendor}-${property_id}-${vendor_property_id}`;
        const validationError = formProps.errors[vendor_property_id];

        return (
          <div key={integrationKey} className="leasing-team-mapping">
            <div className={'modal-field-label'}>
              <label
                className={'required'}
                htmlFor={`${vendor_property_id}-mapping`}
              >
                <strong>
                  {!!this.props.vendorMapping &&
                  this.props.vendorMapping.hasOwnProperty(vendor)
                    ? this.props.vendorMapping[vendor]
                    : ''}
                  {` Mapping ( ID: ${vendor_property_id} ) `}
                </strong>
              </label>
            </div>
            <div className={'modal-field-input'}>
              <select
                id={`${vendor_property_id}-mapping`}
                name={`${vendor_property_id}-mapping`}
                className="knock-react-input"
                onChange={(e) =>
                  this.handleIntegrationChange(e, vendor_property_id)
                }
                value={integration.mapped_agent_id}
              >
                <option value={''}>None</option>
                {this.props.renderIntegrationAgents(integration)}
              </select>

              {validationError && (
                <div className="knock-react-form-hint-error">
                  {validationError}
                </div>
              )}
            </div>
          </div>
        );
      });

    return <div className="leasing-team-mappings">{mappingUI}</div>;
  };

  //Prevent the enter key from bubbling up and closing the form or submitting invalid data
  enterSubmitForm(event, formProps) {
    if (event.charCode === 13) {
      // Enter key
      event.stopPropagation();
      event.preventDefault();
      this.submit(formProps.values);
    }
  }

  submit = (values) => {
    const {
      userIntegrations: integrations,
      is_hub_account,
      currentLeasingTeamIds,
    } = this.state;
    if (this.props.user) {
      const leasingTeamIds =
        this.props.isUserManagementEnabled && currentLeasingTeamIds
          ? { currentLeasingTeamIds }
          : {};

      this.props.onUserUpdated({
        ...values,
        user_id: this.props.user.user_id,
        username: this.state.updated_username || values.username,
        integrations,
        is_hub_account,
        ...leasingTeamIds,
      });
    } else {
      const phone_number = values.phone_number.replace(/\D/g, '');
      this.props.onUserCreated({
        ...values,
        is_hub_account,
        integrations,
        phone_number,
        username: this.state.updated_username,
        currentLeasingTeamIds,
      });
    }
  };

  setLeasingTeams = (leasingTeams, selectedLeasingTeam, isAdding = true) => {
    const currentLeasingTeamIds = leasingTeams.map(
      (leasingTeam) => leasingTeam.leasing_team_id
    );
    if (!currentLeasingTeamIds.includes(this.props.group.leasing_team_id)) {
      currentLeasingTeamIds.push(this.props.group.leasing_team_id);
    }

    let { userIntegrations } = this.state;

    if (selectedLeasingTeam) {
      const { integrations } = selectedLeasingTeam;

      if (isAdding) {
        for (let i = 0; i < integrations.length; i += 1) {
          const { vendor, property_id, vendor_property_id } = integrations[i];
          if (
            !userIntegrations
              .map((i) => i.vendor_property_id)
              .includes(vendor_property_id)
          ) {
            userIntegrations.push({
              mapped_agent_id: '',
              vendor,
              property_id,
              vendor_property_id,
            });
          }
        }
      } else {
        for (let i = 0; i < integrations.length; i += 1) {
          const { vendor_property_id } = integrations[i];
          userIntegrations = userIntegrations.filter(
            (userIntegration) =>
              userIntegration.vendor_property_id !== vendor_property_id
          );
        }
      }
    }

    this.setState({ currentLeasingTeamIds, userIntegrations });
  };

  startChangePassword() {
    this.setState({ isChangingPassword: true });
  }

  submitPasswordUpdate = (values) => {
    this.props.onUserPasswordUpdated(this.props.user.user_id, values.password);
  };

  cancel = (event) => {
    event.preventDefault();

    if (this.state.isChangingPassword)
      this.setState({ isChangingPassword: false });

    this.props.onCancel();
  };
}

export default GroupUserEditor;
