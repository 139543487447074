import { useCallback, useEffect, useState } from 'react';
import { brandContentApi } from '../../../brand-content-api';

export const useBrandContentPreview = ({ brandContent, setErrorMessage }) => {
  const [brandContentPreview, setBrandContentPreview] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const createBrandContentPreview = useCallback(async () => {
    setIsPreviewLoading(true);
    try {
      const html = await brandContentApi.createBrandContentPreview(
        brandContent
      );

      setBrandContentPreview(html);
      setIsPreviewLoading(false);
    } catch (e) {
      setErrorMessage(e.message);
      setIsPreviewLoading(false);
    }
  }, []);

  useEffect(() => {
    createBrandContentPreview();
  }, [createBrandContentPreview]);

  return {
    brandContentPreview,
    isPreviewLoading,
  };
};
