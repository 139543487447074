import { useRef, useState } from 'react';
import PhotosAPI from '../../Pages/Photos/PhotosAPI';
import {
  COMMUNITY_LOGO,
  UPLOAD_ERROR_MESSAGE,
  UPLOAD_STATUS,
} from './constants';

export const useLogoUpload = (onLogoUpload) => {
  const [progressValue, setProgressValue] = useState(0);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null);

  const uploadRequestRef = useRef();

  const abortLogoUpload = () => {
    setUploadStatus(UPLOAD_STATUS.CANCELING);
    uploadRequestRef.current && uploadRequestRef.current.abort();
  };

  const onUploadAbort = () => {
    setProgressValue(0);
    setUploadStatus(null);
    setUploadErrorMessage('');
  };

  const onUploadFailure = () => {
    setUploadErrorMessage(UPLOAD_ERROR_MESSAGE);
    setProgressValue(0);
    setUploadStatus(UPLOAD_STATUS.FAILURE);
  };

  const onUploadProgress = (event) => {
    const { loaded, total } = event;
    const uploadPercentage = Math.floor((loaded / total) * 100);

    setProgressValue(uploadPercentage);
    if (uploadPercentage === 100) {
      setUploadStatus(UPLOAD_STATUS.COMPLETE);
    }
  };

  const onUploadSuccess = (event) => {
    const { status, responseText } = event.target;
    if (status !== 200) {
      onUploadFailure();
      return;
    }

    const response = JSON.parse(responseText);
    const { clUrl, thumbUrl, url } = response.photos[0];
    setUploadStatus(null);
    onLogoUpload({ clUrl, thumbUrl, url });
  };

  const uploadLogo = (file) => {
    setUploadStatus(UPLOAD_STATUS.UPLOADING);
    const xhr = PhotosAPI.uploadForKey(
      COMMUNITY_LOGO,
      [file],
      onUploadSuccess,
      onUploadProgress,
      onUploadFailure,
      onUploadAbort
    );

    uploadRequestRef.current = xhr;
  };

  return {
    abortLogoUpload,
    progressValue,
    setUploadErrorMessage,
    setUploadStatus,
    uploadErrorMessage,
    uploadLogo,
    uploadStatus,
  };
};
