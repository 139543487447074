import { apiRemote } from '../../../../Services/RemoteService';
import * as schedulingApi from '../../SchedulingPage/schedulingApi';
import {
  getQueryString,
  normalizeBatchBrandContent,
  normalizeBrandContent,
  normalizeBrandContentById,
  transformPayload,
} from './utils';

export const ERROR_MESSAGE =
  'An unexpected error has occurred.  Please try again later.';

export const getBrandContent = async (queryParams) => {
  const queryString = getQueryString(queryParams);
  try {
    const response = await apiRemote.get(`admin/brand-content${queryString}`);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { brand_content = [], count: totalPropertyCount } =
      await response.json();

    return {
      brandContent: normalizeBrandContent(brand_content),
      totalPropertyCount,
    };
  } catch (_e) {
    throw new Error(ERROR_MESSAGE);
  }
};

export const getBrandContentById = async (propertyId) => {
  try {
    const response = await apiRemote.get(`admin/brand-content/${propertyId}`);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { brand_content = {} } = await response.json();

    return normalizeBrandContentById(brand_content);
  } catch {
    throw new Error(ERROR_MESSAGE);
  }
};

export const getBrandContentByIds = async (propertyIds) => {
  const queryString = `?property_ids=${propertyIds.join(',')}`;
  try {
    const response = await apiRemote.get(
      `admin/batch-brand-content${queryString}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { brand_content } = await response.json();

    return normalizeBatchBrandContent(brand_content);
  } catch {
    throw new Error(ERROR_MESSAGE);
  }
};

export const getPropertyList = async () => {
  try {
    const properties = await schedulingApi.getPropertyList();
    return properties;
  } catch (_e) {
    throw new Error(ERROR_MESSAGE);
  }
};

export const updateBrandContent = async (payload) => {
  const transformedPayload = transformPayload(payload);

  try {
    const response = await apiRemote.patch(
      'admin/brand-content',
      transformedPayload
    );

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch {
    throw new Error(ERROR_MESSAGE);
  }
};

export const createBrandContentPreview = async (payload) => {
  const transformedPayload = transformPayload(payload);

  try {
    const response = await apiRemote.post('admin/brand-content-preview', {
      ...transformedPayload,
    });

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { html = '' } = await response.json();
    return html;
  } catch {
    throw new Error(ERROR_MESSAGE);
  }
};
