import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  uploadSvgIcon: {
    '&.MuiSvgIcon-root path': {
      stroke: 'currentColor',
    },
  },
});

export const UploadSvgIcon = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classnames(classes.uploadSvgIcon, className)}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 14.7727V5.89999M12 5.89999L15.3 9.22727M12 5.89999L8.7 9.22727M20.25 14.7727V15.8818C20.25 16.4701 20.0182 17.0343 19.6056 17.4503C19.1931 17.8663 18.6335 18.1 18.05 18.1H5.95C5.36652 18.1 4.80695 17.8663 4.39437 17.4503C3.98179 17.0343 3.75 16.4701 3.75 15.8818V14.7727"
          stroke="#4D5066"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

UploadSvgIcon.propTypes = {
  className: PropTypes.string,
};
