import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import { useAppContext } from '../../Context/AppContext';

import AccessPage from '../../Pages/Access/AccessPage';
import { AccessSettingsPage } from '../../Pages/Access';
import AdminsPage from '../../Pages/Users/AdminsPage';
import AiTemplatesPage from '../../Pages/Features/CompanyTemplatesPage/AiTemplates/AiTemplatesPage';
import Attributes from '../../Pages/Attributes/Attributes';
import { BrandContentPage } from '../../Pages/Features/BrandContentPage';
import { CallIntelPage } from '../../Pages/Features/CallIntelPage';
import { CompanyQuickRepliesPage } from '../../Pages/Features/CompanyQuickRepliesPage';
import { CompanyTemplatesPage } from '../../Pages/Features/CompanyTemplatesPage';
import DoorwayPage from '../../Pages/Doorway/DoorwayPage';
import { DripCampaignPage } from '../../Pages/Features/DripCampaignPage';
import IntegrationsPage from '../../Pages/Integrations/IntegrationsPage';
import LoginPage from '../../Pages/Login/LoginPage';
import LogoutPage from '../../Pages/Logout/LogoutPage';
import { NotFoundPage } from '../../Pages/NotFound';
import PhotosPage from '../../Pages/Photos/PhotosPage';
import PricingPage from '../../Pages/Pricing/PricingPage';
import PropertiesPage from '../../Pages/Properties/PropertiesPage';
import ProspectsPage from '../../Pages/Prospects/ProspectsPage';
import RegistrationPage from '../../Pages/Registration/RegistrationPage';
import { SchedulingPage } from '../../Pages/Features/SchedulingPage';
import SettingsPage from '../../Pages/Settings/SettingsPage';
import SheetsPage from '../../Pages/Sheets/SheetsPage';
import SourceListPage from '../../Pages/Sources/SourceListPage';
import SourcesPage from '../../Pages/Sources/SourcesPage';
import SpreadsheetRefreshPage from '../../Pages/SpreadsheetRefresh/SpreadsheetRefreshPage';
import SyncPage from '../../Pages/Sync/SyncPage';
import SyndicationPage from '../../Pages/Syndication/SyndicationPage';
import SSDAPage from '../../Pages/SSDA/SSDAPage';
import TemplatesPage from '../../Pages/Templates/TemplatesPage';
import ToursPage from '../../Pages/Tours/ToursPage';
import UsersPage from '../../Pages/Users/UsersPage';
import UtilitiesPage from '../../Pages/Utilities/UtilitiesPage';
import NewVirtualAgent from '../../Pages/VirtualAgent/NewVirtualAgent';
import ProtectedRoute from '../ProtectedRoute';
import { isMasterRole } from '../utils';
import { ACCESS_SETTINGS } from '../../constants';

const AdminRouter = () => {
  const {
    isCallIntelStandaloneEnabled,
    isCompanyTemplatesEnabled,
    isEnhancedAccessEnabled,
    isSSDAPageEnabled,
  } = useFeatureFlagContext();

  const { isInternalMode } = useAppContext();

  const renderIndexRedirect = () => {
    return <Redirect to="/sources" />;
  };

  return (
    <Switch>
      <Route exact path="/" render={renderIndexRedirect} />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.INTEGRATIONS_PAGE}
        component={IntegrationsPage}
        path="/integrations"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.SOURCES_PAGE}
        component={SourcesPage}
        path="/sources"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.SOURCE_LIST_PAGE}
        component={SourceListPage}
        path="/source-list"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.USERS_PAGE}
        component={UsersPage}
        path="/users"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.ADMINS_PAGE}
        component={AdminsPage}
        path="/admins"
      />

      {isInternalMode && (
        <ProtectedRoute component={SyndicationPage} path="/syndication" />
      )}

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.PRICING_PAGE}
        component={PricingPage}
        path="/pricing"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.PROPERTIES_PAGE}
        component={PropertiesPage}
        path="/properties"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.ATTRIBUTES_PAGE}
        component={Attributes}
        path="/attributes"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.PHOTOS_PAGE}
        component={PhotosPage}
        path="/photos"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.REGISTRATION_PAGE}
        component={RegistrationPage}
        path="/registration"
      />
      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.SCHEDULING_PAGE}
        component={SchedulingPage}
        path="/scheduling"
      />

      {isSSDAPageEnabled && (
        <ProtectedRoute
          accessSetting={ACCESS_SETTINGS.SSDA_PAGE}
          component={SSDAPage}
          path="/ssda"
        />
      )}

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.QUICK_REPLIES_PAGE}
        component={CompanyQuickRepliesPage}
        path="/quick-replies"
      />

      {isInternalMode && (
        <ProtectedRoute component={ProspectsPage} path="/prospects" />
      )}

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.BRAND_CONTENT_PAGE}
        component={BrandContentPage}
        path="/brand-content"
      />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.DOORWAY_PAGE}
        component={DoorwayPage}
        path="/doorway"
      />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.DRIP_CAMPAIGN_PAGE}
        component={DripCampaignPage}
        path="/drip-campaign"
      />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.SHEETS_PAGE}
        component={SheetsPage}
        path="/sheets"
      />

      {isCallIntelStandaloneEnabled && isInternalMode && (
        <ProtectedRoute component={CallIntelPage} path="/call-intel" />
      )}

      {isCompanyTemplatesEnabled && (
        <ProtectedRoute
          accessSetting={ACCESS_SETTINGS.TEMPLATES_PAGE}
          component={CompanyTemplatesPage}
          path="/templates"
        />
      )}

      {!isCompanyTemplatesEnabled && (
        <ProtectedRoute
          accessSetting={ACCESS_SETTINGS.TEMPLATES_PAGE}
          component={TemplatesPage}
          path="/templates"
        />
      )}

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.AI_TEMPLATE_PAGE}
        component={AiTemplatesPage}
        path="/ai-templates"
      />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.SETTINGS_PAGE}
        component={SettingsPage}
        path="/settings"
      />

      {isInternalMode && (
        <ProtectedRoute component={UtilitiesPage} path="/utilities" />
      )}

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.SYNC_PAGE}
        component={SyncPage}
        path="/sync"
      />

      {isMasterRole() && (
        <ProtectedRoute
          component={isEnhancedAccessEnabled ? AccessSettingsPage : AccessPage}
          path="/access"
        />
      )}

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.VIRTUAL_AGENT_PAGE}
        component={NewVirtualAgent}
        path="/virtual-agent"
      />

      <Route component={LoginPage} path="/login" />
      <Route component={LoginPage} path="/autologin" />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.TOURS_PAGE}
        component={ToursPage}
        isAuthenticationBypassed
        path="/tours"
      />

      <Route component={LogoutPage} path="/logout" />

      <ProtectedRoute
        accessSetting={ACCESS_SETTINGS.REFRESH_SPREADSHEET_PAGE}
        component={SpreadsheetRefreshPage}
        isAuthenticationBypassed
        path="/refresh-spreadsheet"
      />

      <Route component={NotFoundPage} path="*" />
    </Switch>
  );
};

export default AdminRouter;
