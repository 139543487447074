import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@knockrentals/knock-shared-web';
import ErrorDialog from '../../Pages/Features/common/ErrorDialog/ErrorDialog';
import Logo from './Logo';
import UploadPreview from './UploadPreview';
import UploadProgress from './UploadProgress';
import UploadTarget from './UploadTarget';
import { useLogoUpload } from './use-logo-upload';
import './_CommunityLogo.scss';

const CommunityLogo = ({ logoUrl, onLogoRemove, onLogoUpload }) => {
  const [filePreviewUrl, setFilePreviewUrl] = useState('');
  const fileRef = useRef(null);

  const {
    abortLogoUpload,
    progressValue,
    setUploadErrorMessage,
    setUploadStatus,
    uploadLogo,
    uploadErrorMessage,
    uploadStatus,
  } = useLogoUpload(onLogoUpload);

  const abortUpload = () => {
    abortLogoUpload();
    fileRef.current = null;
  };

  const cancelUpload = () => {
    setFilePreviewUrl('');
    fileRef.current = null;
  };

  const closeErrorDialog = () => {
    fileRef.current = null;
    setUploadErrorMessage('');
    setUploadStatus(null);
  };

  const handleFileUpload = () => {
    setFilePreviewUrl('');
    uploadLogo(fileRef.current);
  };

  const removeLogoOnClick = () => {
    onLogoRemove();
  };

  const setUploadFile = (file) => {
    fileRef.current = file;
    setFilePreviewUrl(URL.createObjectURL(file));
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={Boolean(filePreviewUrl)}
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      >
        {filePreviewUrl && (
          <UploadPreview
            fileUrl={filePreviewUrl}
            onCancel={cancelUpload}
            onUpload={handleFileUpload}
          />
        )}
      </Dialog>

      <ErrorDialog
        closeDialog={closeErrorDialog}
        errorMessage={uploadErrorMessage}
        isOpen={Boolean(uploadErrorMessage)}
      />

      <div className="logo-upload-container">
        {logoUrl && (
          <Logo logoUrl={logoUrl} removeLogoOnClick={removeLogoOnClick} />
        )}

        <div className="upload-container">
          {!uploadStatus ? (
            <UploadTarget setUploadFile={setUploadFile} />
          ) : (
            <UploadProgress
              abortUpload={abortUpload}
              fileName={fileRef.current.name}
              fileSize={fileRef.current.size}
              fileUrl={URL.createObjectURL(fileRef.current)}
              progressValue={progressValue}
              uploadStatus={uploadStatus}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

CommunityLogo.propTypes = {
  logoUrl: PropTypes.string,
  onLogoRemove: PropTypes.func,
  onLogoUpload: PropTypes.func,
};

export default CommunityLogo;
