import { useState } from 'react';
import RegistrationAPI from './RegistrationAPI';
import { useAppContext } from '../../Context/AppContext';
import { normalizeRegistration, validateRegistration } from './utils';
import {
  BRAND_REGISTRATION_FIELDS,
  ERROR_MESSAGES,
  MESSAGING_SERVICE_FIELDS,
  REGISTRATION_LOOKUP_SIDS,
  REGISTRATION_TYPES,
} from './constants';

const { BRAND_REGISTRATION_SID, MESSAGING_SERVICE_SID } =
  REGISTRATION_LOOKUP_SIDS;

const {
  A2P_CAMPAIGN,
  BRAND_REGISTRATION,
  CUSTOMER_PROFILE,
  MESSAGING_SERVICE,
} = REGISTRATION_TYPES;

const submitRegistration = async ({
  brandRegistrationSid,
  messagingServiceSid,
}) => {
  try {
    const response = await RegistrationAPI.createRegistration(
      brandRegistrationSid,
      messagingServiceSid
    );

    if (!response.ok || response.status !== 201) {
      throw new Error();
    }

    window.location.reload();
  } catch (e) {
    throw new Error();
  }
};

const fetchRegistration = async (registrationSids) => {
  try {
    const response = await RegistrationAPI.getRegistration(
      registrationSids[BRAND_REGISTRATION_SID],
      registrationSids[MESSAGING_SERVICE_SID]
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const responseBody = await response.json();

    const registration = normalizeRegistration(responseBody);

    return registration;
  } catch {
    throw new Error();
  }
};

const getIsCurrentRegistration = (registrationSids, registration) => {
  if (!registration) {
    return false;
  }

  const {
    [BRAND_REGISTRATION_SID]: brandRegistrationSidLookup,
    [MESSAGING_SERVICE_SID]: messagingServiceSidLookup,
  } = registrationSids;

  const brandRegistrationSid =
    registration[BRAND_REGISTRATION][BRAND_REGISTRATION_FIELDS.SID];
  const messagingServiceSid =
    registration[MESSAGING_SERVICE][MESSAGING_SERVICE_FIELDS.SID];

  return (
    brandRegistrationSidLookup === brandRegistrationSid &&
    messagingServiceSidLookup === messagingServiceSid
  );
};

const registrationSidsInitialState = {
  [BRAND_REGISTRATION_SID]: '',
  [MESSAGING_SERVICE_SID]: '',
};

const registrationErrorsInitialState = {
  [A2P_CAMPAIGN]: {},
  [BRAND_REGISTRATION]: {},
  [CUSTOMER_PROFILE]: {},
  [MESSAGING_SERVICE]: {},
};

export const useLinkRegistration = () => {
  const [registrationSids, setRegistrationSids] = useState(
    registrationSidsInitialState
  );
  const [registration, setRegistration] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationErrors, setRegistrationErrors] = useState(
    registrationErrorsInitialState
  );

  const isCurrentRegistration = getIsCurrentRegistration(
    registrationSids,
    registration
  );

  const { companyName } = useAppContext();

  const lookUpRegistration = async () => {
    try {
      const registration = await fetchRegistration(registrationSids);

      setRegistration(registration);

      const registrationErrorMessages = validateRegistration(registration);

      if (registrationErrorMessages) {
        setRegistrationErrors(registrationErrorMessages);
      }
    } catch {
      setErrorMessage(ERROR_MESSAGES.LOOKUP);
    }
  };

  const linkRegistration = async () => {
    try {
      await submitRegistration(registrationSids);
    } catch {
      setErrorMessage(ERROR_MESSAGES.LINK);
    }
  };

  return {
    companyName,
    errorMessage,
    isCurrentRegistration,
    linkRegistration,
    lookUpRegistration,
    registration,
    registrationErrors,
    registrationSids,
    setErrorMessage,
    setRegistrationSids,
  };
};
