import {
  InputCheckbox,
  Switch,
  makeStyles,
  Button,
} from '@knockrentals/knock-shared-web';
import { Toaster } from '@knockrentals/knock-react';
import DataTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import EditButton from './Components/EditButton';
import { FilterProperty, FilterRadio } from './Components/FilterBoxes';
import * as VirtualAgentAPI from '../VirtualAgentAPI';
import * as AiEmailAPI from '../aiEmailAPI';
import AiBulkEditDrawer from './Components/AiBulkEditDrawer';
import TitleWithTooltip from './Components/TitleWithTooltip';

const TOOL_TIPS = {
  VOICE: 'Enable if AI Voice was ordered by property',
  CHAT: 'Enable if AI Chat was ordered by property',
  SMS: 'Enable if AI SMS was ordered by property',
  EMAIL: 'Enable if AI Email was ordered by property',
};

const useStyles = makeStyles({
  filterAndEditContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  filterHeader: {
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    padding: '8px 10px 8px 10px',
    textDecoration: 'none',
    cursor: 'pointer',
    border: '1px solid white',
  },
});

const ProductEnabledSettings = ({
  productsEnabled,
  setProductsEnabled,
  companyId,
}) => {
  const classes = useStyles();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [showedProperties, setShowedProperties] = useState([]);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState(
    productsEnabled.map(({ propertyId }) => propertyId)
  );
  const [filters, setFilters] = useState({
    sms: null,
    voice: null,
    chat: null,
    email: null,
  });
  const FIELDS = {
    voiceProductEnabled: 'voice_product_enabled',
    chatProductEnabled: 'chat_product_enabled',
    smsProductEnabled: 'sms_product_enabled',
    emailProductEnabled: 'ai_email_enabled',
  };

  const applyFilters = (productsEnabled, filters) => {
    const filteredProperties = productsEnabled.filter((property) => {
      const isSelected = selectedProperties.length
        ? selectedProperties.includes(property.propertyId)
        : true;

      const smsEnabled =
        filters.sms === null ||
        (filters.sms && property.smsProductEnabled) ||
        (!filters.sms && !property.smsProductEnabled);
      const voiceEnabled =
        filters.voice === null ||
        (filters.voice && property.voiceProductEnabled) ||
        (!filters.voice && !property.voiceProductEnabled);
      const chatEnabled =
        filters.chat === null ||
        (filters.chat && property.chatProductEnabled) ||
        (!filters.chat && !property.chatProductEnabled);
      const emailEnabled =
        filters.email === null ||
        (filters.email && property.emailProductEnabled !== 'OFF') ||
        (!filters.email && property.emailProductEnabled === 'OFF');

      return (
        isSelected & smsEnabled && voiceEnabled && chatEnabled && emailEnabled
      );
    });

    setShowedProperties(filteredProperties);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const isAnyFilterActive = useMemo(() => {
    return (
      Object.values(filters).some((value) => value !== null) ||
      (selectedProperties.length > 0 &&
        selectedProperties.length !== productsEnabled.length)
    );
  }, [filters, selectedProperties, productsEnabled]);

  const handleClearFilters = () => {
    setFilters({
      sms: null,
      voice: null,
      chat: null,
      email: null,
    });
    setSelectedProperties(productsEnabled.map(({ propertyId }) => propertyId));
  };

  const handleSelectProperty = (propertyId) => {
    setSelectedPropertyIds((prevSelectedPropertyIds) => {
      if (prevSelectedPropertyIds.includes(propertyId)) {
        return prevSelectedPropertyIds.filter((id) => id !== propertyId);
      } else {
        return [...prevSelectedPropertyIds, propertyId];
      }
    });
  };

  const handleSelectAllOnChange = () => {
    const selectedCount = selectedPropertyIds.length;
    setSelectedPropertyIds(
      selectedCount === showedProperties.length
        ? []
        : showedProperties.map(({ propertyId }) => propertyId)
    );
  };

  const handlePropertySelect = (properties) => {
    setSelectedProperties(properties);
  };

  const handleSwitchToggle = (propertyId, field, value) => {
    const updatedShowedProperties = showedProperties.map((property) => {
      if (property.propertyId === propertyId) {
        return { ...property, [field]: value };
      }
      return property;
    });

    const updatedAllProperties = productsEnabled.map((channelProperty) => {
      if (channelProperty.propertyId === propertyId) {
        return {
          ...channelProperty,
          [field]: value,
          ...(field === 'emailProductEnabled' && { aiEmailEnabled: value }),
        };
      }

      return channelProperty;
    });

    setProductsEnabled(updatedAllProperties);
    setShowedProperties(updatedShowedProperties);
  };

  const updateBulkChannelProperties = (
    productEnabledPayload,
    aiEmailPayload
  ) => {
    const updatedChannelProduct = productsEnabled.map((property) => {
      const productEnabledUpdate = productEnabledPayload.find(
        (p) => p.property_id === property.propertyId
      );
      const aiEmailUpdate = aiEmailPayload.find(
        (p) => p.property_id === property.propertyId
      );

      if (productEnabledUpdate || aiEmailUpdate) {
        return {
          ...property,
          voiceProductEnabled:
            productEnabledUpdate?.voice_product_enabled ??
            property.voiceProductEnabled,
          chatProductEnabled:
            productEnabledUpdate?.chat_product_enabled ??
            property.chatProductEnabled,
          smsProductEnabled:
            productEnabledUpdate?.sms_product_enabled ??
            property.smsProductEnabled,
          emailProductEnabled:
            aiEmailUpdate?.ai_email_enabled ?? property.emailProductEnabled,
        };
      }
      return property;
    });

    setProductsEnabled(updatedChannelProduct);
    setShowedProperties(updatedChannelProduct);
  };

  const saveProductsEnabled = async (field, value, propertyId) => {
    if (FIELDS[field] === 'ai_email_enabled') {
      const ai_email_value = value === 'OFF' ? 'SETUP' : 'OFF';
      const payload = [
        {
          property_id: propertyId,
          company_id: companyId,
          [FIELDS[field]]: ai_email_value,
        },
      ];
      try {
        await AiEmailAPI.editAiEmailEnabled(payload);
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        handleSwitchToggle(propertyId, field, ai_email_value);
      } catch (error) {
        Toaster.showToast(
          'Error Saving Products',
          2000,
          Toaster.ToastClasses.error
        );
      }
    } else {
      const payload = {
        pmc_id: companyId,
        properties: [
          {
            property_id: propertyId,
            [FIELDS[field]]: value,
          },
        ],
      };
      try {
        await VirtualAgentAPI.saveProductsEnabled(payload);
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        handleSwitchToggle(propertyId, field, value);
      } catch (error) {
        Toaster.showToast(
          'Error Saving Products',
          2000,
          Toaster.ToastClasses.error
        );
      }
    }
  };

  useEffect(() => {
    applyFilters(productsEnabled, filters, selectedProperties);
  }, [filters, selectedProperties, productsEnabled]);

  const getColumns = () => [
    {
      align: 'left',
      width: '50px',
      title: (
        <InputCheckbox
          color="primary"
          onChange={handleSelectAllOnChange}
          checked={
            !!showedProperties.length &&
            selectedPropertyIds.length === showedProperties.length
          }
          indeterminate={
            selectedPropertyIds.length > 0 &&
            selectedPropertyIds.length < showedProperties.length
          }
          data-testid="select-all-checkbox"
        />
      ),
      render: (rowData) => (
        <InputCheckbox
          color="primary"
          onChange={() => handleSelectProperty(rowData.propertyId)}
          checked={selectedPropertyIds.includes(rowData.propertyId)}
        />
      ),
      cellStyle: {
        position: 'sticky',
        left: '0px',
        zIndex: 3,
        backgroundColor: '#fff',
      },
      headerStyle: {
        left: '0px',
        zIndex: 4,
      },
    },
    {
      align: 'left',
      title: 'Properties',
      width: '100px',
      render: (rowData) =>
        productsEnabled.find(
          ({ propertyId }) => propertyId === rowData.propertyId
        )?.propertyName || '',
      cellStyle: {
        position: 'sticky',
        left: '50px',
        zIndex: 3,
        backgroundColor: '#fff',
      },
      headerStyle: {
        left: '50px',
        zIndex: 4,
      },
    },
    {
      align: 'center',
      title: TitleWithTooltip('Voice'),
      tooltip: TOOL_TIPS['VOICE'],
      field: 'voiceProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch
          color="primary"
          name="voice-product-enabled"
          checked={!!rowData.voiceProductEnabled}
          onChange={() => {
            saveProductsEnabled(
              'voiceProductEnabled',
              !rowData.voiceProductEnabled,
              rowData.propertyId
            );
          }}
        />
      ),
    },
    {
      align: 'center',
      title: TitleWithTooltip('Chat'),
      tooltip: TOOL_TIPS['CHAT'],
      field: 'chatProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch
          color="primary"
          name="chat-product-enabled"
          checked={!!rowData.chatProductEnabled}
          onChange={() => {
            saveProductsEnabled(
              'chatProductEnabled',
              !rowData.chatProductEnabled,
              rowData.propertyId
            );
          }}
        />
      ),
    },
    {
      align: 'center',
      title: TitleWithTooltip('SMS'),
      tooltip: TOOL_TIPS['SMS'],
      field: 'smsProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch
          color="primary"
          name="sms-product-enabled"
          checked={!!rowData.smsProductEnabled}
          onChange={() => {
            saveProductsEnabled(
              'smsProductEnabled',
              !rowData.smsProductEnabled,
              rowData.propertyId
            );
          }}
        />
      ),
    },
    {
      align: 'center',
      title: TitleWithTooltip('Email'),
      tooltip: TOOL_TIPS['EMAIL'],
      field: 'emailProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch
          color="primary"
          name="email-product-enabled"
          checked={rowData.emailProductEnabled !== 'OFF'}
          onChange={() => {
            saveProductsEnabled(
              'emailProductEnabled',
              rowData.emailProductEnabled,
              rowData.propertyId
            );
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div className={classes.filterAndEditContainer}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <FilterProperty
            properties={productsEnabled.map((property) => ({
              id: property.propertyId,
              name: property.propertyName,
              selected: selectedProperties.includes(property.id),
            }))}
            onPropertySelect={handlePropertySelect}
          />
          {['Voice', 'Chat', 'SMS', 'Email'].map((filterName) => (
            <FilterRadio
              key={filterName}
              filterName={filterName}
              value={filters[filterName.toLowerCase()]}
              onFilterChange={(value) =>
                handleFilterChange(filterName.toLowerCase(), value)
              }
            />
          ))}
          <Button
            className={classes.filterHeader}
            variant="outlined"
            color="primary"
            onClick={handleClearFilters}
            disabled={!isAnyFilterActive}
          >
            Clear Filters
          </Button>
        </div>
        <EditButton
          selected={selectedPropertyIds}
          onClick={() => setIsEditDrawerOpen(true)}
        />
      </div>
      <AiBulkEditDrawer
        isEditDrawerOpen={isEditDrawerOpen}
        setIsEditDrawerOpen={setIsEditDrawerOpen}
        title={'Edit Product Settings'}
        properties={productsEnabled.filter((channel) =>
          selectedPropertyIds.includes(channel.propertyId)
        )}
        companyId={companyId}
        handleBulkToggle={updateBulkChannelProperties}
      />
      <DataTable
        className="scheduling-grid-data-table"
        columns={getColumns()}
        data={showedProperties}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          grouping: false,
          headerStyle: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '32px',
            position: 'sticky',
            top: 0,
            zIndex: 2,
          },
          padding: 'dense',
          paging: false,
          rowStyle: {
            fontSize: '14px',
            fontWeight: 400,
          },
          search: false,
          sorting: false,
          toolbar: false,
          maxBodyHeight: '65vh',
        }}
      />
    </div>
  );
};

ProductEnabledSettings.propTypes = {
  productsEnabled: PropTypes.arrayOf(PropTypes.object),
  setProductsEnabled: PropTypes.func,
  companyId: PropTypes.number,
};

export default ProductEnabledSettings;
