import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles((theme) => ({
  logoAvatar: {
    width: '100px',
    height: '100px',
    margin: '0 auto',
  },
  removeLogoButton: {
    color: theme.palette.error.dark,
  },
}));

const Logo = ({ logoUrl, removeLogoOnClick }) => {
  const classes = useStyles();
  return (
    <div className="logo-wrapper">
      <Avatar
        alt="property logo"
        className={classes.logoAvatar}
        src={logoUrl}
        variant="rounded"
      />

      <Button
        className={classes.removeLogoButton}
        onClick={removeLogoOnClick}
        variant="text"
      >
        Remove Logo
      </Button>
    </div>
  );
};

Logo.propTypes = {
  logoUrl: PropTypes.string,
  removeLogoOnClick: PropTypes.func,
};

export default Logo;
