import React, { FC } from 'react';
import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core';

export interface LinearProgressProps extends MuiLinearProgressProps {}

export const LinearProgress: FC<LinearProgressProps> = (props) => {
  return <MuiLinearProgress aria-label="progress bar" {...props} />;
};
