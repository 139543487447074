import React from 'react';
import { Button, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { ErrorDialog } from '../Features/common/ErrorDialog';
import RegistrationDisplay from './RegistrationDisplay';
import RegistrationLookup from './RegistrationLookup';
import { useLinkRegistration } from './use-link-registration';
import { hasRegistrationErrorMessages } from './utils';
import { REGISTRATION_LOOKUP_SIDS } from './constants';

const useStyles = makeStyles({
  buttonDescription: { marginTop: '40px' },
  linkRegistrationButton: { marginTop: '20px' },
});

const LinkRegistration = () => {
  const classes = useStyles();

  const {
    companyName,
    errorMessage,
    isCurrentRegistration,
    linkRegistration,
    lookUpRegistration,
    registration,
    registrationErrors,
    registrationSids,
    setErrorMessage,
    setRegistrationSids,
  } = useLinkRegistration();

  const isLinkButtonDisabled =
    hasRegistrationErrorMessages(registrationErrors) || !isCurrentRegistration;

  return (
    <section className="brand-registration-data-link">
      <ErrorDialog
        closeDialog={() => {
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <RegistrationLookup
        brandRegistrationSid={
          registrationSids[REGISTRATION_LOOKUP_SIDS.BRAND_REGISTRATION_SID]
        }
        isCurrentRegistration={isCurrentRegistration}
        lookUpRegistration={lookUpRegistration}
        messagingServiceSid={
          registrationSids[REGISTRATION_LOOKUP_SIDS.MESSAGING_SERVICE_SID]
        }
        setRegistrationSids={setRegistrationSids}
      />

      {registration && (
        <React.Fragment>
          <RegistrationDisplay
            registration={registration}
            registrationErrors={registrationErrors}
          />

          <Text className={classes.buttonDescription}>
            Link this Twilio Brand Registration, A2P Campaign, and Messaging
            Service
          </Text>

          <div className="registration-button-wrapper">
            <Button
              className={classes.linkRegistrationButton}
              disabled={isLinkButtonDisabled}
              onClick={linkRegistration}
            >
              Link to Knock Company {companyName}
            </Button>
          </div>
        </React.Fragment>
      )}
    </section>
  );
};

export default LinkRegistration;
