import { useCallback, useEffect, useState } from 'react';
import { initializeApp } from './utils';
import { useServices } from './useServices';
import { useMergeTags } from './useMergeTags';
import { ADMIN_USER_ROLES } from '../../../constants';

export const appDataInitialState = {
  accessMap: {},
  companyId: null,
  companyName: null,
  isInternalMode: false,
  isLoggedIn: false,
  isReady: false,
  userId: null,
  userType: null,
};

export const useApp = () => {
  const [appData, setAppData] = useState(appDataInitialState);

  const updateAppData = useCallback((dataUpdate) => {
    setAppData((prevState) => ({
      ...prevState,
      ...dataUpdate,
    }));
  }, []);

  const setIsReady = useCallback(
    (isReady) => {
      updateAppData({ isReady });
    },
    [updateAppData]
  );

  useEffect(() => {
    initializeApp(updateAppData);
  }, [updateAppData]);

  const {
    accessMap,
    companyId,
    companyName,
    isInternalMode,
    isLoggedIn,
    isReady,
    userId,
    userRole,
    userType,
  } = appData;

  useServices({
    companyId,
    companyName,
    isInternalMode,
    userType,
    userId,
  });

  const shouldFetchMergeTags = Boolean(companyId);
  const { mergeTags = {} } = useMergeTags(shouldFetchMergeTags);

  const isAuthorized = (setting) =>
    userRole === ADMIN_USER_ROLES.MASTER || accessMap[setting];

  return {
    companyId,
    companyName,
    isAuthorized,
    isInternalMode,
    isLoggedIn,
    isReady,
    mergeTags,
    setIsReady,
  };
};
