import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { Button, InputText, makeStyles } from '@knockrentals/knock-shared-web';
import { validateRegistrationSids } from './utils';
import {
  REGISTRATION_LOOKUP_LABELS,
  REGISTRATION_LOOKUP_SIDS,
} from './constants';

const { BRAND_REGISTRATION_SID, MESSAGING_SERVICE_SID } =
  REGISTRATION_LOOKUP_SIDS;

const useStyles = makeStyles({
  sidInput: {
    width: '360px',
  },
});

const RegistrationLookup = ({
  brandRegistrationSid,
  isCurrentRegistration,
  lookUpRegistration,
  messagingServiceSid,
  setRegistrationSids,
}) => {
  const [validationErrorMessages, setValidationErrorMessages] = useState({});

  const classes = useStyles();

  const isLookupDisabled =
    isCurrentRegistration ||
    !brandRegistrationSid ||
    !messagingServiceSid ||
    Object.values(validationErrorMessages).some((error) => error);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setRegistrationSids((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const handleOnFocus = (e) => {
    const { name } = e.target;
    setValidationErrorMessages((prevState) => ({ ...prevState, [name]: '' }));
  };

  const handleRegistrationLookup = () => {
    const errorMessages = validateRegistrationSids({
      [BRAND_REGISTRATION_SID]: brandRegistrationSid,
      [MESSAGING_SERVICE_SID]: messagingServiceSid,
    });

    if (errorMessages) {
      setValidationErrorMessages((prevState) => ({
        ...prevState,
        ...errorMessages,
      }));
      return;
    }

    lookUpRegistration();
  };

  return (
    <div className="brand-registration-lookup">
      <div className="lookup-sid-inputs">
        <InputText
          className={classes.sidInput}
          error={Boolean(validationErrorMessages[BRAND_REGISTRATION_SID])}
          helperText={validationErrorMessages[BRAND_REGISTRATION_SID] || ''}
          label={REGISTRATION_LOOKUP_LABELS[BRAND_REGISTRATION_SID]}
          name={BRAND_REGISTRATION_SID}
          placeholder="e.g. BNxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          value={brandRegistrationSid}
          requiredMessage="required"
        />

        <InputText
          className={classes.sidInput}
          error={Boolean(validationErrorMessages[MESSAGING_SERVICE_SID])}
          helperText={validationErrorMessages[MESSAGING_SERVICE_SID] || ''}
          label={REGISTRATION_LOOKUP_LABELS[MESSAGING_SERVICE_SID]}
          name={MESSAGING_SERVICE_SID}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          placeholder="e.g. MGxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          requiredMessage="required"
          value={messagingServiceSid}
        />
      </div>

      <div className="registration-button-wrapper">
        <Button
          disabled={isLookupDisabled}
          startIcon={<SearchIcon />}
          onClick={handleRegistrationLookup}
        >
          Lookup
        </Button>
      </div>
    </div>
  );
};

RegistrationLookup.propTypes = {
  brandRegistrationSid: PropTypes.string,
  isCurrentRegistration: PropTypes.bool,
  lookUpRegistration: PropTypes.func,
  messagingServiceSid: PropTypes.string,
  setRegistrationSids: PropTypes.func,
};

export default RegistrationLookup;
