export const removeSpaces = (value) => value.replace(/\s+/g, '');

export const whiteSpaceRestrictValue = (setFieldValue) => (e) => {
  const cleanValue = removeSpaces(e.target.value);
  setFieldValue(e.target.name, removeSpaces(cleanValue));
};

export const trimValue = (setFieldValue) => (e) => {
  setFieldValue(e.target.name, e.target.value.trim());
};

export const validateUrl = (url) => {
  const urlRegex = /^(http|https):\/\/((([a-zA-Z0-9._%+-]+(:[a-zA-Z0-9._%+-]*)?)@)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:\d+)?(\/[\w#!:.?+=&%@!\-/]*)?)$/
  return urlRegex.test(url);
};

export const sortCaseInsensitive = (field) => {
  const collator = new Intl.Collator('en', {
    caseFirst: 'upper',
  });
  return (a, b) => collator.compare(a[field], b[field]);
};
