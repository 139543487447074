import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { CKEDITOR_URL } from '../../../../../../../constants';

CKEditor.editorUrl = CKEDITOR_URL;

const EMAIL_PREVIEW_ARIA_LABEL = 'brand content email preview';
const EDITOR_MIN_HEIGHT = 450;
const EDITOR_NAME = 'brand-content-email-preview';

const editorConfig = {
  allowedContent: true,
  autoGrow_minHeight: EDITOR_MIN_HEIGHT,
  extraPlugins: 'autogrow',
  removeDialogTabs: 'image:advanced;link:advanced;table:advanced',
  removePlugins:
    'clipboard,image2,liststyle,uploadfile,pastefromgdocs,pastefromlibreoffice,pastefromword,pastetext,pastetools,placeholder,uploadimage,elementspath,toolbar,resize,widget,uploadwidget',
  resize_enabled: false,
  startupShowBorders: false,
};

export const BrandContentEmailPreview = ({ brandContentPreview }) => {
  return (
    <div aria-label={EMAIL_PREVIEW_ARIA_LABEL}>
      <CKEditor
        config={editorConfig}
        data={brandContentPreview}
        name={EDITOR_NAME}
        readOnly
        style={{ border: 'none' }}
      />
    </div>
  );
};

BrandContentEmailPreview.propTypes = {
  brandContentPreview: PropTypes.string,
};
