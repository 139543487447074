import { useEffect, useState } from 'react';
import { getEmailMergeTags } from '../../../Services/message-service';

export const useMergeTags = (shouldFetchMergeTags) => {
  const [mergeTags, setMergeTags] = useState();

  useEffect(() => {
    const fetchMergeTags = async () => {
      try {
        const response = await getEmailMergeTags();
        const data = await response.json();
        const { merge_tags: mergeTags = {} } = data || {};
        setMergeTags(mergeTags);
      } catch (_e) {
        setMergeTags({});
      }
    };

    if (shouldFetchMergeTags) {
      fetchMergeTags();
    }
  }, [shouldFetchMergeTags]);

  return {
    mergeTags,
  };
};
