import React from 'react';
import PropTypes from 'prop-types';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  Avatar,
  IconButton,
  LinearProgress,
  makeStyles,
  NamedColors,
  Text,
} from '@knockrentals/knock-shared-web';
import { getMegabytes } from './utils';

export const CANCEL_UPLOAD_LABEL = 'cancel upload';

const useStyles = makeStyles({
  cancelIconButton: {
    color: NamedColors.slate[700],
  },
  progressBar: {
    flexGrow: 1,
  },
  progressContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    padding: '16px',
  },
});

const UploadProgress = ({
  abortUpload,
  fileName,
  fileSize,
  fileUrl,
  progressValue,
  uploadStatus,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.progressContainer}>
      <Avatar variant="rounded" src={fileUrl} />

      <div className={classes.progressBar}>
        <Text variant="subtitle1">{fileName}</Text>
        <Text color="secondary" gutterBottom variant="body2">
          {getMegabytes(fileSize).toFixed(2)} mb • {uploadStatus}
        </Text>

        <LinearProgress variant="determinate" value={progressValue} />
      </div>

      <IconButton
        aria-label={CANCEL_UPLOAD_LABEL}
        className={classes.cancelIconButton}
        onClick={abortUpload}
      >
        <CancelOutlinedIcon />
      </IconButton>
    </div>
  );
};

UploadProgress.propTypes = {
  abortUpload: PropTypes.func,
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  fileUrl: PropTypes.string,
  progressValue: PropTypes.number,
  uploadStatus: PropTypes.string,
};

export default UploadProgress;
