import { apiRemote } from '../../Services/RemoteService';
import { AuthenticationService } from '@knockrentals/knock-react';
import { BASE_API_URI } from '../../config';

class PhotosAPI {
  static getPhotos() {
    return apiRemote
      .get(`admin/photos/properties`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static updatePhotos(propertyId, requestData, callback) {
    return apiRemote
      .put(`admin/photos/properties/${propertyId}`, requestData)
      .then((response) => response.json())
      .then(() => callback())
      .catch((e) => e);
  }

  static uploadForKey(
    key,
    files,
    uploadComplete,
    uploadProgress,
    uploadFailed,
    uploadAbort
  ) {
    const url = BASE_API_URI + 'admin/photos/upload';

    const formData = this.createFormData(key, files);
    const xhr = new XMLHttpRequest();

    if (uploadComplete) {
      xhr.addEventListener('load', uploadComplete, false);
    }

    if (uploadProgress) {
      xhr.upload.addEventListener('progress', uploadProgress, false);
    }

    if (uploadFailed) {
      xhr.addEventListener('error', uploadFailed, false);
    }

    if (uploadAbort) {
      xhr.addEventListener('abort', uploadAbort, false);
    }

    const token = AuthenticationService.getToken();

    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.send(formData);
    return xhr;
  }

  static createFormData(key, files) {
    const uploadForm = new FormData();
    uploadForm.append('key', key);

    const fileOrder = files.map((file, i) => {
      uploadForm.append(file.name, file);
      return {
        i,
        photo: file.name,
      };
    });

    uploadForm.append('order', JSON.stringify(fileOrder));
    uploadForm.append('key', key);

    return uploadForm;
  }
}

export default PhotosAPI;
