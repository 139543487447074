export const ERROR_MESSAGES = {
  LINK: 'We were unable to link this Twilio registration. Please contact Knock Support for assistance',
  LOOKUP:
    'We are currently unable to retrieve the twilio registration details for the provided SIDs. Please try again',
  UNKNOWN: 'Unrecognized error encountered, contact Knock Support',
  USER: 'Only master users are authorized to view registration details and register this company to Twilio.',
};

export const TOAST_ERROR_MESSAGES = {
  EXISTING_REGISTRATION_FETCH: 'Could not retrieve existing registration',
};

export const VALIDATION_ERRORS = {
  A2P_CAMPAIGN_STATUS: 'A2P Campaign status must be VERIFIED',
  CUSTOMER_PROFILE_SID_MISMATCH:
    'The Brand Registration Customer Profile SID must match the Customer Profile SID',
  BRAND_REGISTRATION_SID_MISMATCH:
    'The Brand Registration SID and Twilio A2P Brand Registration SID must match',
  BRAND_REGISTRATION_STATUS: 'Brand Registration status must be APPROVED',
  BRAND_REGISTRATION_TYPE: 'Brand Registration type must be STANDARD',
};

export const REGISTRATION_LOOKUP_SIDS = {
  BRAND_REGISTRATION_SID: 'brandRegistrationSid',
  MESSAGING_SERVICE_SID: 'messagingServiceSid',
};

export const REGISTRATION_LOOKUP_LABELS = {
  [REGISTRATION_LOOKUP_SIDS.BRAND_REGISTRATION_SID]:
    'Twilio Brand Registration SID',
  [REGISTRATION_LOOKUP_SIDS.MESSAGING_SERVICE_SID]:
    'Twilio Messaging Service SID',
};

export const REGISTRATION_PREFIXES = {
  [REGISTRATION_LOOKUP_SIDS.BRAND_REGISTRATION_SID]: 'BN',
  [REGISTRATION_LOOKUP_SIDS.MESSAGING_SERVICE_SID]: 'MG',
};

export const REGISTRATION_PREFIX_ERRORS = {
  [REGISTRATION_LOOKUP_SIDS.BRAND_REGISTRATION_SID]:
    'Twilio brand registration sid must start with a "BN..."',
  [REGISTRATION_LOOKUP_SIDS.MESSAGING_SERVICE_SID]:
    'Twilio messaging service sid must start with a "MG..."',
};

export const REGISTRATION_TYPES = {
  A2P_CAMPAIGN: 'a2pCampaign',
  BRAND_REGISTRATION: 'brandRegistration',
  CUSTOMER_PROFILE: 'customerProfile',
  MESSAGING_SERVICE: 'messagingService',
  PHONE_REGISTRATION: 'phoneRegistration',
};

export const A2P_CAMPAIGN_FIELDS = {
  BRAND_REGISTRATION_SID: 'brandRegistrationSid',
  CAMPAIGN_STATUS: 'campaignStatus',
  DATE_CREATED: 'dateCreated',
  DESCRIPTION: 'description ',
  MESSAGING_SERVICE_SID: 'messagingServiceSid',
  SID: 'sid',
  US_APP_TO_PERSON_USECASE: 'usAppToPersonUseCase ',
};

export const BRAND_REGISTRATION_FIELDS = {
  BRAND_SCORE: 'brandScore',
  BRAND_TYPE: 'brandType',
  CUSTOMER_PROFILE_BUNDLE_SID: 'customerProfileBundleSid',
  DATE_CREATED: 'dateCreated',
  SID: 'sid',
  STATUS: 'status',
};

export const CUSTOMER_PROFILE_FIELDS = {
  EMAIL: 'email',
  FRIENDLY_NAME: 'friendlyName',
  SID: 'sid',
};

export const MESSAGING_SERVICE_FIELDS = {
  DATE_CREATED: 'dateCreated',
  FRIENDLY_NAME: 'friendlyName',
  SID: 'sid',
};

export const PHONE_REGISTRATION_FIELDS = {
  CAPACITY: 'capacity',
  TOTAL_REGISTERED: 'totalRegistered',
  TOTAL_UNREGISTERED: 'totalUnregistered',
  TWILIO_LIMIT: 'twilioLimit',
};

export const REGISTRATION_LABELS = {
  [REGISTRATION_TYPES.A2P_CAMPAIGN]: {
    [A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID]: 'Brand Registration SID',
    [A2P_CAMPAIGN_FIELDS.CAMPAIGN_STATUS]: 'Campaign Status',
    [A2P_CAMPAIGN_FIELDS.DATE_CREATED]: 'Date Created',
    [A2P_CAMPAIGN_FIELDS.DESCRIPTION]: 'Description ',
    [A2P_CAMPAIGN_FIELDS.MESSAGING_SERVICE_SID]: 'Messaging Service SID',
    [A2P_CAMPAIGN_FIELDS.SID]: 'SID',
    [A2P_CAMPAIGN_FIELDS.US_APP_TO_PERSON_USECASE]: 'Use Case',
  },
  [REGISTRATION_TYPES.BRAND_REGISTRATION]: {
    [BRAND_REGISTRATION_FIELDS.BRAND_SCORE]: 'Brand Score',
    [BRAND_REGISTRATION_FIELDS.BRAND_TYPE]: 'Brand Type',
    [BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID]:
      'Customer Profile Bundle Sid',
    [BRAND_REGISTRATION_FIELDS.DATE_CREATED]: 'Date Created',
    [BRAND_REGISTRATION_FIELDS.SID]: 'SID',
    [BRAND_REGISTRATION_FIELDS.STATUS]: 'Status',
  },
  [REGISTRATION_TYPES.CUSTOMER_PROFILE]: {
    [CUSTOMER_PROFILE_FIELDS.EMAIL]: 'Email',
    [CUSTOMER_PROFILE_FIELDS.FRIENDLY_NAME]: 'Friendly Name',
    [CUSTOMER_PROFILE_FIELDS.SID]: 'SID',
  },
  [REGISTRATION_TYPES.MESSAGING_SERVICE]: {
    [MESSAGING_SERVICE_FIELDS.DATE_CREATED]: 'Date Created',
    [MESSAGING_SERVICE_FIELDS.FRIENDLY_NAME]: 'Friendly Name',
    [MESSAGING_SERVICE_FIELDS.SID]: 'SID',
  },
  [REGISTRATION_TYPES.PHONE_REGISTRATION]: {
    [PHONE_REGISTRATION_FIELDS.CAPACITY]: 'Capacity',
    [PHONE_REGISTRATION_FIELDS.TOTAL_REGISTERED]: 'Total Registered',
    [PHONE_REGISTRATION_FIELDS.TOTAL_UNREGISTERED]: 'Total Unregistered',
    [PHONE_REGISTRATION_FIELDS.TWILIO_LIMIT]: 'Twilio limit',
  },
};

export const REGISTRATION_TITLES = {
  [REGISTRATION_TYPES.A2P_CAMPAIGN]: 'A2P Campaign',
  [REGISTRATION_TYPES.BRAND_REGISTRATION]: 'Brand Registration',
  [REGISTRATION_TYPES.CUSTOMER_PROFILE]: 'Customer Profile',
  [REGISTRATION_TYPES.MESSAGING_SERVICE]: 'Messaging Service',
  [REGISTRATION_TYPES.PHONE_REGISTRATION]: 'Phone Registration',
};
