import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@knockrentals/knock-shared-web';
import FeatureCard from '../../../../common/FeatureCard';
import FeatureCardContent from '../../../../common/FeatureCardContent';
import { FeatureActions } from '../../../../common/FeatureActions';
import { BrandContentEmailPreview } from './BrandContentEmailPreview';
import { useBrandContentPreview } from './use-brand-content-preview';

export const PREVIEW_TITLE = 'Brand Content Preview';

export const BrandContentPreview = ({
  brandContent,
  closeDrawer,
  handleSaveOnClick,
  setErrorMessage,
}) => {
  const { brandContentPreview, isPreviewLoading } = useBrandContentPreview({
    brandContent,
    setErrorMessage,
  });

  return (
    <FeatureCard
      closeDrawer={closeDrawer}
      subheader={brandContent.propertyName}
      title={PREVIEW_TITLE}
    >
      <FeatureCardContent>
        {!isPreviewLoading && (
          <Card variant="outlined">
            <BrandContentEmailPreview
              brandContentPreview={brandContentPreview}
            />
          </Card>
        )}
      </FeatureCardContent>
      <FeatureActions
        handleCancelOnClick={closeDrawer}
        handleSaveOnClick={handleSaveOnClick}
      />
    </FeatureCard>
  );
};

BrandContentPreview.propTypes = {
  brandContent: PropTypes.object,
  closeDrawer: PropTypes.func,
  handleSaveOnClick: PropTypes.func,
  setErrorMessage: PropTypes.func,
};
