import { Toaster } from '@knockrentals/knock-react';
import {
  A2P_CAMPAIGN_FIELDS,
  BRAND_REGISTRATION_FIELDS,
  CUSTOMER_PROFILE_FIELDS,
  MESSAGING_SERVICE_FIELDS,
  REGISTRATION_PREFIXES,
  REGISTRATION_PREFIX_ERRORS,
  REGISTRATION_TYPES,
  VALIDATION_ERRORS,
} from './constants';

const {
  A2P_CAMPAIGN,
  BRAND_REGISTRATION,
  CUSTOMER_PROFILE,
  MESSAGING_SERVICE,
} = REGISTRATION_TYPES;

export const showToastError = (errorMessage) => {
  Toaster.showToast(errorMessage, 2000, Toaster.ToastClasses.error);
};

export const getRegistrationQueryParams = (
  twilioBrandRegistrationSid,
  messagingServiceSid
) => {
  if (!twilioBrandRegistrationSid && !messagingServiceSid) {
    return '';
  }

  const params = [
    twilioBrandRegistrationSid && `sid=${twilioBrandRegistrationSid}`,
    messagingServiceSid && `messaging_service_sid=${messagingServiceSid}`,
  ];

  return `?${params.filter((param) => param).join('&')}`;
};

export const normalizeA2PCampaign = (a2pCampaign = {}) => ({
  [A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID]:
    a2pCampaign.brand_registration_sid,
  [A2P_CAMPAIGN_FIELDS.CAMPAIGN_STATUS]: a2pCampaign.campaign_status,
  [A2P_CAMPAIGN_FIELDS.DATE_CREATED]: a2pCampaign.date_created,
  [A2P_CAMPAIGN_FIELDS.DESCRIPTION]: a2pCampaign.description,
  [A2P_CAMPAIGN_FIELDS.MESSAGING_SERVICE_SID]:
    a2pCampaign.messaging_service_sid,
  [A2P_CAMPAIGN_FIELDS.SID]: a2pCampaign.sid,
  [A2P_CAMPAIGN_FIELDS.US_APP_TO_PERSON_USECASE]:
    a2pCampaign.us_app_to_person_usecase,
});

export const normalizeBrandRegistration = (brandRegistration = {}) => ({
  [BRAND_REGISTRATION_FIELDS.BRAND_SCORE]: brandRegistration.brand_score,
  [BRAND_REGISTRATION_FIELDS.BRAND_TYPE]: brandRegistration.brand_type,
  [BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID]:
    brandRegistration.customer_profile_bundle_sid,
  [BRAND_REGISTRATION_FIELDS.DATE_CREATED]: brandRegistration.date_created,
  [BRAND_REGISTRATION_FIELDS.SID]: brandRegistration.sid,
  [BRAND_REGISTRATION_FIELDS.STATUS]: brandRegistration.status,
});

export const normalizeCustomerProfile = (customerProfile = {}) => ({
  [CUSTOMER_PROFILE_FIELDS.EMAIL]: customerProfile.email,
  [CUSTOMER_PROFILE_FIELDS.FRIENDLY_NAME]: customerProfile.friendly_name,
  [CUSTOMER_PROFILE_FIELDS.SID]: customerProfile.sid,
});

export const normalizeMessagingService = (messagingService = {}) => ({
  [MESSAGING_SERVICE_FIELDS.DATE_CREATED]: messagingService.date_created,
  [MESSAGING_SERVICE_FIELDS.FRIENDLY_NAME]: messagingService.friendly_name,
  [MESSAGING_SERVICE_FIELDS.SID]: messagingService.sid,
});

export const normalizeRegistration = (registration) => {
  return {
    [A2P_CAMPAIGN]: normalizeA2PCampaign(registration.twilio_a2p_campaign),
    [BRAND_REGISTRATION]: normalizeBrandRegistration(
      registration.twilio_brand_registration
    ),
    [CUSTOMER_PROFILE]: normalizeCustomerProfile(
      registration.twilio_customer_profile
    ),
    [MESSAGING_SERVICE]: normalizeMessagingService(
      registration.twilio_messaging_service
    ),
  };
};

const validateSid = (name, value) => {
  if (!value) {
    return 'Required';
  }
  const prefix = REGISTRATION_PREFIXES[name];

  if (!value.startsWith(prefix)) {
    return REGISTRATION_PREFIX_ERRORS[name];
  }
};

export const validateRegistrationSids = (sids) => {
  const validationErrorMessages = Object.entries(sids).reduce(
    (errorMessages, [name, value]) => {
      const errorMessage = validateSid(name, value);
      if (errorMessage) {
        errorMessages[name] = errorMessage;
      }
      return errorMessages;
    },
    {}
  );

  if (Object.keys(validationErrorMessages).length) {
    return validationErrorMessages;
  }
};

export const hasRegistrationErrorMessages = (messages) =>
  Object.values(messages).some(
    (errorMessages) => Object.keys(errorMessages).length > 0
  );

export const validateRegistration = ({
  [A2P_CAMPAIGN]: a2pCampaign,
  [BRAND_REGISTRATION]: brandRegistration,
  [CUSTOMER_PROFILE]: customerProfile,
  [MESSAGING_SERVICE]: messagingService,
}) => {
  const messages = {
    [A2P_CAMPAIGN]: {},
    [BRAND_REGISTRATION]: {},
    [CUSTOMER_PROFILE]: {},
    [MESSAGING_SERVICE]: {},
  };

  if (
    brandRegistration[BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID] !==
    customerProfile[CUSTOMER_PROFILE_FIELDS.SID]
  ) {
    const message = VALIDATION_ERRORS.CUSTOMER_PROFILE_SID_MISMATCH;
    messages[BRAND_REGISTRATION][
      BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID
    ] = message;
    messages[CUSTOMER_PROFILE][CUSTOMER_PROFILE_FIELDS.SID] = message;
  }

  if (brandRegistration[BRAND_REGISTRATION_FIELDS.BRAND_TYPE] !== 'STANDARD') {
    messages[BRAND_REGISTRATION][BRAND_REGISTRATION_FIELDS.BRAND_TYPE] =
      VALIDATION_ERRORS.BRAND_REGISTRATION_TYPE;
  }

  if (brandRegistration[BRAND_REGISTRATION_FIELDS.STATUS] !== 'APPROVED') {
    messages[BRAND_REGISTRATION][BRAND_REGISTRATION_FIELDS.STATUS] =
      VALIDATION_ERRORS.BRAND_REGISTRATION_STATUS;
  }

  if (a2pCampaign[A2P_CAMPAIGN_FIELDS.CAMPAIGN_STATUS] !== 'VERIFIED') {
    messages[A2P_CAMPAIGN][A2P_CAMPAIGN_FIELDS.CAMPAIGN_STATUS] =
      VALIDATION_ERRORS.A2P_CAMPAIGN_STATUS;
  }

  if (
    a2pCampaign[A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID] !==
    brandRegistration[BRAND_REGISTRATION_FIELDS.SID]
  ) {
    const message = VALIDATION_ERRORS.BRAND_REGISTRATION_SID_MISMATCH;
    messages[A2P_CAMPAIGN][A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID] =
      message;
    messages[BRAND_REGISTRATION][BRAND_REGISTRATION_FIELDS.SID] = message;
  }

  if (hasRegistrationErrorMessages(messages)) {
    return messages;
  }
};
