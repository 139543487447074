import {
  ACCEPTED_IMAGE_FORMATS,
  INVALID_FILE_MESSAGES,
  MAX_LOGO_FILE_SIZE,
  MEGABYTE,
} from './constants';

export const acceptedImageFormatString = ACCEPTED_IMAGE_FORMATS.join(',');

export const getMegabytes = (bytes) => bytes / MEGABYTE;

export const isValidSize = (file) => file.size <= MAX_LOGO_FILE_SIZE;
export const isValidType = (file) => ACCEPTED_IMAGE_FORMATS.includes(file.type);

export const validateFile = (file) => {
  if (!isValidType(file)) {
    return INVALID_FILE_MESSAGES.TYPE;
  }

  if (!isValidSize(file)) {
    return INVALID_FILE_MESSAGES.SIZE;
  }
};
