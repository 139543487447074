import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { CommunityLogo } from '../../../Components/CommunityLogo';

export const PropertyLogo = ({ logos = [], setLogos }) => {
  const logoUrl = logos.length ? logos[0].url : '';
  const originalLogoRef = useRef(null);

  if (!originalLogoRef.current && logos.length) {
    originalLogoRef.current = logoUrl;
  }

  const onLogoRemove = () => {
    const originalLogoUrl = originalLogoRef.current;
    if (logoUrl === originalLogoUrl) {
      setLogos('logos', []);
    } else {
      const originalLogos = [
        {
          thumbUrl: originalLogoUrl,
          url: originalLogoUrl,
        },
      ];
      setLogos('logos', originalLogos);
    }
  };

  const onLogoUpload = ({ thumbUrl, url }) => {
    const logos = [
      {
        thumbUrl,
        url,
      },
    ];
    setLogos('logos', logos);
  };

  return (
    <ThemeProvider>
      <CommunityLogo
        logoUrl={logoUrl}
        onLogoRemove={onLogoRemove}
        onLogoUpload={onLogoUpload}
      />
    </ThemeProvider>
  );
};

PropertyLogo.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      thumbUrl: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  setLogos: PropTypes.func,
};
