import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BrandContent } from './BrandContent';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import FeatureDrawer from '../../common/FeatureDrawer';
import FeatureCard from '../../common/FeatureCard';
import FeatureCardContent from '../../common/FeatureCardContent';
import { FeatureActions } from '../../common/FeatureActions';
import { BrandContentPreview } from './BrandContent/BrandContentPreview';
import { useFeatureFlagContext } from '../../../../Context/FeatureFlagContext';
import { usePropertyBrandContent } from './use-property-brand-content';
import { getConfirmationSaveMessage, getFeatureCardSubheader } from './utils';
import {
  CONFIRMATION_BUTTON_TEXT,
  CONFIRMATION_MODES,
  CONFIRMATION_SECONDARY_BUTTON_TEXT,
  CONFIRMATION_TITLES,
} from '../constants';

export const TITLE = 'Brand Content';

const BrandContentDrawer = ({
  closeDrawer,
  mergeTags,
  propertyList,
  reloadBrandContent,
  setErrorMessage,
}) => {
  const [confirmationMode, setConfirmationMode] = useState(null);
  const [brandContentPreview, setBrandContentPreview] = useState({});

  const {
    brandContent,
    hasBrandContentChanged,
    isBatchUpdate,
    onLogoUpload,
    removeLogo,
    saveBrandContent,
    updateBrandColor,
    updateBrandContent,
  } = usePropertyBrandContent({
    closeDrawer,
    propertyList,
    reloadBrandContent,
    setErrorMessage,
  });

  const { isEnhancedBrandContentEnabled } = useFeatureFlagContext();

  const handleConfirmation = () => {
    setConfirmationMode(null);
    saveBrandContent();
  };

  const handleSaveOnClick = () => {
    setConfirmationMode(CONFIRMATION_MODES.SAVE);
  };

  const getSaveMessage = () => {
    const messageConfig = {
      isHeaderUpdating: true,
      isFooterUpdating: true,
      propertyCount: propertyList.length,
    };
    return getConfirmationSaveMessage(messageConfig);
  };

  const getConfirmationMessage = () => {
    return confirmationMode === CONFIRMATION_MODES.SAVE && getSaveMessage();
  };

  const openPreviewDrawer = () => {
    setBrandContentPreview(brandContent);
  };

  const closePreviewDrawer = () => {
    setBrandContentPreview({});
  };

  const isDrawerOpen = Boolean(brandContentPreview.propertyId);
  const isPreviewButtonEnabled =
    isEnhancedBrandContentEnabled && !isBatchUpdate;

  return (
    <React.Fragment>
      <FeatureDrawer disableEnforceFocus isOpen={isDrawerOpen}>
        <BrandContentPreview
          brandContent={brandContent}
          closeDrawer={closePreviewDrawer}
          handleSaveOnClick={handleSaveOnClick}
          setErrorMessage={setErrorMessage}
        />
      </FeatureDrawer>
      <FeatureCard
        closeDrawer={closeDrawer}
        subheader={getFeatureCardSubheader(propertyList)}
        title={TITLE}
      >
        <FeatureCardContent>
          <BrandContent
            brandContent={brandContent}
            isBatchUpdate={isBatchUpdate}
            mergeTags={mergeTags}
            propertyList={propertyList}
            onLogoUpload={onLogoUpload}
            removeLogo={removeLogo}
            updateBrandColor={updateBrandColor}
            updateBrandContent={updateBrandContent}
          />
        </FeatureCardContent>
        <FeatureActions
          handleCancelOnClick={closeDrawer}
          handlePreviewOnClick={isPreviewButtonEnabled && openPreviewDrawer}
          handleSaveOnClick={handleSaveOnClick}
          isSaveButtonDisabled={!hasBrandContentChanged}
        />
      </FeatureCard>

      <ConfirmationDialog
        closeDialog={() => setConfirmationMode(null)}
        handleConfirmation={handleConfirmation}
        isOpen={Boolean(confirmationMode)}
        message={getConfirmationMessage()}
        primaryButtonText={CONFIRMATION_BUTTON_TEXT[confirmationMode]}
        secondaryButtonText={
          CONFIRMATION_SECONDARY_BUTTON_TEXT[confirmationMode]
        }
        title={CONFIRMATION_TITLES[confirmationMode]}
      />
    </React.Fragment>
  );
};

BrandContentDrawer.propTypes = {
  closeDrawer: PropTypes.func,
  mergeTags: PropTypes.array,
  propertyList: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  reloadBrandContent: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default BrandContentDrawer;
