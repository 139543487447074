import React, { useEffect, useState } from 'react';

import { Toaster } from '@knockrentals/knock-react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LoadingOverlay,
  Tab,
  TabPanel,
  Tabs,
  ThemeProvider,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as schedulingApi from '../../Features/SchedulingPage/schedulingApi';
import * as VirtualAgentAPI from '../VirtualAgentAPI';
import VirtualAgentResponsesDownloadModal from './Components/VirtualAgentResponsesDownloadModal';
import VirtualAgentLeasingResponses from './VirtualAgentLeasingResponses';
import VirtualAgentResidentResponses from './VirtualAgentResidentResponses';
import { TABS } from './constants';

const useStyles = makeStyles(() => ({
  tabPanel: {
    marginTop: '10px',
  },

  divider: {
    marginTop: '0 !important',
    position: 'relative',
    top: '-1px',
  },

  subtitle: {
    marginTop: '10px',
    marginBottom: '25px',
  },
}));

const TABS_NAMES = {
  leasing: 'Leasing Responses',
  resident: 'Resident Responses',
};

const VirtualAgentResponses = ({ showNewUI }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(TABS.LEASING);
  const [propertiesById, setPropertiesById] = useState({});
  const [intents, setIntents] = useState([]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadModalErrorMessage, setDownloadModalErrorMessage] =
    useState('');

  const handleDownloadConfirmation = async (
    propertyIds,
    downloadBoth = false
  ) => {
    setIsDownloadModalOpen(false);
    setIsDownloading(true);

    const selectedProperties = [];
    propertyIds.forEach((propertyId) => {
      if (propertiesById[propertyId]) {
        selectedProperties.push({propertyId: propertiesById[propertyId].id, propertyName: propertiesById[propertyId].name});
      }
    });
    
    try {
      if (currentTab === TABS.RESIDENT || downloadBoth) {
        try {
          let residentCsvResponse =
            await VirtualAgentAPI.downloadResidentPropertyResponses(selectedProperties);
          getCSVFile(residentCsvResponse, 'resident_responses.csv');
        } catch (err) {
          console.error('Error downloading resident responses:', err);
        }
      }

      if (currentTab === TABS.LEASING || downloadBoth) {
        try {
          let leasingCsvResponse =
            await VirtualAgentAPI.downloadLeasingPropertyResponses(selectedProperties);
          getCSVFile(leasingCsvResponse, 'leasing_responses.csv');
        } catch (err) {
          console.error('Error downloading leasing responses:', err);
        }
      }

      Toaster.showToast(
        'Responses downloaded successfully',
        2000,
        Toaster.ToastClasses.success
      );
    } catch (e) {
      Toaster.showToast(
        'An error occurred while downloading the responses.',
        2000,
        Toaster.ToastClasses.error
      );
    }
    setIsDownloading(false);
  };

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getProperties = async () => {
    try {
      const propertiesResponse = await schedulingApi.getPropertyList();
      const propertiesById = {};
      propertiesResponse.forEach((property) => {
        propertiesById[property.id] = property;
      });

      const virtualAgentSettigs =
        await VirtualAgentAPI.getVirtualAgentsByPropertyIds(
          Object.keys(propertiesById),
          1,
          1000
        );

      if (virtualAgentSettigs != null && virtualAgentSettigs.results) {
        virtualAgentSettigs.results.forEach((agent) => {
          const { property_id: propertyId } = agent;
          if (propertiesById[propertyId]) {
            propertiesById[propertyId].agent = agent;
          }
        });
      }

      setPropertiesById(propertiesById);
    } catch (e) {
      Toaster.showToast(
        'Error retrieving properties',
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const getIntents = async () => {
    try {
      const intentsResponse = await VirtualAgentAPI.getLeasingIntentsV2();
      setIntents(intentsResponse.intents);
    } catch (e) {
      Toaster.showToast(
        'Error retrieving intents',
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const getCSVFile = (csvResponse, filename) => {
    const blob = new Blob([csvResponse], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const download_link = Object.assign(document.createElement('a'), {
      href: url,
      download: filename,
      style: 'display: none',
    });

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([getProperties(), getIntents()]);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {!showNewUI && (
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            setIsDownloadModalOpen(true);
          }}
          startIcon={<GetAppIcon />}
          endIcon={isDownloading ? <CircularProgress size="20px" /> : ''}
          disabled={isDownloading}
          data-testid="downloadall"
        >
          Download All Responses
        </Button>
      )}
      <VirtualAgentResponsesDownloadModal
        isOpen={isDownloadModalOpen}
        closeDialog={() => {
          setIsDownloadModalOpen(false);
        }}
        handleConfirmation={(propertyIds) =>
          handleDownloadConfirmation(propertyIds, true)
        }
        setErrorMessage={setDownloadModalErrorMessage}
        errorMessage={downloadModalErrorMessage}
        title="Select the properties to include in responses export"
      />
      <ThemeProvider>
        <LoadingOverlay
          open={isLoading}
          style={{ position: 'absolute', opacity: '0.5' }}
        />
        <Box data-testid="new-va-agent-config">
          <Tabs
            value={currentTab}
            onChange={handleTabsChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label={TABS_NAMES.leasing} value={TABS.LEASING} />
            <Tab label={TABS_NAMES.resident} value={TABS.RESIDENT} />
          </Tabs>
          <Divider className={classes.divider} />
        </Box>

        <TabPanel
          id="ChannelTabPanel"
          value={currentTab}
          index={TABS.LEASING}
          className={classes.tabPanel}
        >
          <VirtualAgentLeasingResponses
            intents={intents}
            properties={Object.values(propertiesById).sort((a, b) => a.name.localeCompare(b.name))}
            onConfirmation={handleDownloadConfirmation}
            currentTab={currentTab}
          />
        </TabPanel>

        <TabPanel
          id="ChannelTabPanel"
          value={currentTab}
          index={TABS.RESIDENT}
          className={classes.tabPanel}
        >
          <VirtualAgentResidentResponses
            properties={Object.values(propertiesById).sort((a, b) => a.name.localeCompare(b.name))}
            onConfirmation={handleDownloadConfirmation}
            currentTab={currentTab}
          />
        </TabPanel>
      </ThemeProvider>
    </>
  );
};

export default VirtualAgentResponses;
