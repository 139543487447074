import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardMedia,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  logoImage: {
    objectFit: 'scale-down',
  },
  previewCard: {
    borderRadius: 0,
  },
});

const UploadPreview = ({ fileUrl, onCancel, onUpload }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogContent>
        <Card className={classes.previewCard} variant="outlined">
          <CardMedia
            className={classes.logoImage}
            component="img"
            src={fileUrl}
          />
        </Card>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="outlined">
          Cancel
        </Button>

        <Button onClick={onUpload} color="primary">
          Upload
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

UploadPreview.propTypes = {
  fileUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onUpload: PropTypes.func,
};

export default UploadPreview;
