import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import { CardContent, FormHelperText } from '@material-ui/core';
import {
  Avatar,
  Card,
  makeStyles,
  NamedColors,
  Text,
} from '@knockrentals/knock-shared-web';
import { UploadSvgIcon } from '../Icons';
import { acceptedImageFormatString, validateFile } from './utils';
import { MAX_MEGABYTES_FILE_SIZE } from './constants';

export const HIDDEN_INPUT_LABEL = 'file-input';

const useStyles = makeStyles({
  clickText: {
    color: NamedColors.denim[400],
  },
  errorMessage: {
    textAlign: 'center',
  },
  uploadAvatar: {
    backgroundColor: NamedColors.denim[200],
    margin: '8px auto',
  },
  uploadCard: {
    borderColor: NamedColors.slate[300],
    borderStyle: 'dashed',
    cursor: 'pointer',
    '& .drop-target-drag-over-active': {
      backgroundColor: NamedColors.slate[50],
    },
  },
  uploadCardContent: {
    textAlign: 'center',
  },
});

const UploadTarget = ({ setUploadFile }) => {
  const [invalidFileMessage, setInvalidFileMessage] = useState('');

  const dropFrameRef = useRef();
  const fileInputRef = useRef();

  const classes = useStyles();

  const handleDropTargetClick = () => {
    setInvalidFileMessage('');
    fileInputRef.current.click();
  };

  const handleFile = ([file] = [{}]) => {
    const validationErrorMessage = validateFile(file);
    if (validationErrorMessage) {
      setInvalidFileMessage(validationErrorMessage);
      return;
    }
    setUploadFile(file);
  };

  return (
    <Card className={classes.uploadCard} variant="outlined">
      <FileDrop
        draggingOverTargetClassName="drop-target-drag-over-active"
        frame={dropFrameRef.current}
        onFrameDragEnter={() => {
          setInvalidFileMessage('');
        }}
        onDrop={handleFile}
        onTargetClick={handleDropTargetClick}
      >
        <CardContent className={classes.uploadCardContent} ref={dropFrameRef}>
          <Avatar variant="rounded" className={classes.uploadAvatar}>
            <UploadSvgIcon color="primary" />
          </Avatar>

          <Text>
            <Text className={classes.clickText} component="span">
              Click to upload
            </Text>{' '}
            or drag and drop
          </Text>

          <Text color="secondary" variant="body2">
            {`SVG, PNG, JPG or GIF (max. ${MAX_MEGABYTES_FILE_SIZE}MB)`}
          </Text>

          {invalidFileMessage && (
            <FormHelperText className={classes.errorMessage} error>
              {invalidFileMessage}
            </FormHelperText>
          )}
        </CardContent>
      </FileDrop>

      <input
        accept={acceptedImageFormatString}
        aria-label={HIDDEN_INPUT_LABEL}
        aria-hidden
        hidden
        onChange={(e) => handleFile(e.target.files)}
        ref={fileInputRef}
        type="file"
      />
    </Card>
  );
};

UploadTarget.propTypes = {
  setUploadFile: PropTypes.func,
};

export default UploadTarget;
