export const BYTE = 1;
export const KILOBYTE = 1024 * BYTE;
export const MAX_MEGABYTES_FILE_SIZE = 10;
export const MEGABYTE = 1024 * KILOBYTE;

export const MAX_LOGO_FILE_SIZE = MEGABYTE * MAX_MEGABYTES_FILE_SIZE;

export const ACCEPTED_IMAGE_FORMATS = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/svg+xml',
];

export const COMMUNITY_LOGO = 'community-logo';

export const INVALID_FILE_MESSAGES = {
  TYPE: 'Invalid file format. Logos must be formatted as gif, jpg, png or svg',
  SIZE: `Invalid file size. Logos must be ${MAX_MEGABYTES_FILE_SIZE}mb or less in size`,
};

export const UPLOAD_ERROR_MESSAGE =
  'The image failed to upload. Please try again.';

export const UPLOAD_STATUS = {
  COMPLETE: 'complete',
  UPLOADING: 'uploading',
  FAILURE: 'failed',
  CANCELING: 'canceling',
};
