import { useFeatureFlagContext } from '../../../../../Context/FeatureFlagContext';
import { getSelectOptions } from './utils';

import { CAMPAIGN_TRIGGER, TRIGGER_TYPES } from './constants';

export const useFeatureFlaggedOptions = ({ recipient }) => {
  const {
    isAiEmailTriggerEnabled,
    isConfigurableStaleTriggerEnabled
  } =
    useFeatureFlagContext();

  const getTriggerOptions = getSelectOptions(CAMPAIGN_TRIGGER);

  const filterOptions = (option) => {
    switch (option.value) {
      case TRIGGER_TYPES.AI_EMAIL:
        return isAiEmailTriggerEnabled;
      case TRIGGER_TYPES.CONFIGURABLE_STALE:
        return isConfigurableStaleTriggerEnabled;
      default:
        return true;
    }
  };

  return {
    triggerOptions: getTriggerOptions(recipient).filter(filterOptions),
  };
};
