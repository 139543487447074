import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { IconButton, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { CommunityLogo } from '../../../../../../../Components/CommunityLogo';

const useStyles = makeStyles((theme) => ({
  expandMoreButton: {
    color: theme.palette.text.primary,
  },
  communityLogoWrapper: {
    marginBottom: '16px',
    '& .logo-upload-container': {
      justifyContent: 'center',
    },
  },
}));

const LogoUpload = ({ logoUrl, onLogoUpload, removeLogo }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const classes = useStyles();

  const toggleIsExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <Text component="span">Logo Upload</Text>
      <IconButton
        className={classes.expandMoreButton}
        onClick={toggleIsExpanded}
      >
        <ExpandMore />
      </IconButton>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <div className={classes.communityLogoWrapper}>
          <CommunityLogo
            logoUrl={logoUrl}
            onLogoRemove={removeLogo}
            onLogoUpload={onLogoUpload}
          />
        </div>
      </Collapse>
    </React.Fragment>
  );
};

LogoUpload.propTypes = {
  logoUrl: PropTypes.string,
  onLogoUpload: PropTypes.func,
  removeLogo: PropTypes.func,
};

export default LogoUpload;
