import React from 'react';

const TitleWithTooltip = (title) => {
  return (
    <span>
      {title}
      <i className="ml-1 fa-regular fa-question-circle tooltip"></i>
    </span>
  )
}

export default TitleWithTooltip;