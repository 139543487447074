import {
  apiRemote,
  phoneRegistrationRemote,
} from '../../Services/RemoteService';
import { getRegistrationQueryParams } from './utils';

class RegistrationAPI {
  static createRegistration(
    twilioBrandRegistrationSid,
    twilioMessagingServiceSid
  ) {
    return apiRemote.post(`admin/registration`, {
      twilio_brand_registration_sid: twilioBrandRegistrationSid,
      ...(twilioMessagingServiceSid && {
        twilio_messaging_service_sid: twilioMessagingServiceSid,
      }),
    });
  }

  static getCompanyPhoneRegistration = async () => {
    try {
      const response = await phoneRegistrationRemote.get('/company');

      if (response.status !== 200) {
        throw new Error();
      }

      return await response.json();
    } catch {
      throw new Error();
    }
  };

  static getPropertyPhoneRegistration = async (propertyId) => {
    try {
      const response = await phoneRegistrationRemote.get(
        `/property?propertyId=${propertyId}`
      );

      if (response.status !== 200) {
        throw new Error();
      }

      return await response.json();
    } catch {
      throw new Error();
    }
  };

  static getRegistration(sid, messagingServiceSid) {
    const queryParams = getRegistrationQueryParams(sid, messagingServiceSid);

    return apiRemote.get(`admin/registration${queryParams}`);
  }
}

export default RegistrationAPI;
