import React, { useState } from 'react';
import { Menu, MenuItem, ThemeProvider } from '@knockrentals/knock-shared-web';
import { VerticalNavbarLink } from '@knockrentals/knock-react';
import './_SupportNavbarLink.scss';
import { Link } from '@material-ui/core';

const SupportLink = ({ children, url, onClick }) => (
  <Link
    color="inherit"
    variant="body2"
    underline="none"
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
  >
    {children}
  </Link>
);

const SupportNavbarLink = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider>
      <div>
        <a href={'#'} onClick={handleOpenDropdown}>
          <VerticalNavbarLink>
            <i className="fa fa-life-ring" />
            Support
            <i className="fa fa-angle-down fa-fw support-dropdown-icon" />
          </VerticalNavbarLink>
        </a>

        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseDropdown}
        >
          <MenuItem>
            <SupportLink
              url="https://kb.realpage.com/"
              onClick={handleCloseDropdown}
            >
              <i className="fa fa-fw fa-life-ring support-link-icon" />
              Self-help Center
            </SupportLink>
          </MenuItem>
          <MenuItem>
            <SupportLink
              url="https://www.realpage.com/training/webinars/?category=knock"
              onClick={handleCloseDropdown}
            >
              <i className="fa fa-fw fa-calendar support-link-icon" />
              Live Training
            </SupportLink>
          </MenuItem>
          <MenuItem>
            <SupportLink
              url="https://realpagelearning.com/v5/"
              onClick={handleCloseDropdown}
            >
              <i className="fa fa-fw fa-graduation-cap support-link-icon" />
              On-demand Training
            </SupportLink>
          </MenuItem>
        </Menu>
      </div>
    </ThemeProvider>
  );
};

export default SupportNavbarLink;
