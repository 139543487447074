import React from 'react';
import PropTypes from 'prop-types';
import RegistrationCard from './RegistrationCard';
import {
  A2P_CAMPAIGN_FIELDS,
  BRAND_REGISTRATION_FIELDS,
  CUSTOMER_PROFILE_FIELDS,
  MESSAGING_SERVICE_FIELDS,
  PHONE_REGISTRATION_FIELDS,
  REGISTRATION_LABELS,
  REGISTRATION_TITLES,
  REGISTRATION_TYPES,
} from './constants';

const {
  A2P_CAMPAIGN,
  BRAND_REGISTRATION,
  CUSTOMER_PROFILE,
  MESSAGING_SERVICE,
  PHONE_REGISTRATION,
} = REGISTRATION_TYPES;

export const registrationFields = {
  [A2P_CAMPAIGN]: [
    A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID,
    A2P_CAMPAIGN_FIELDS.CAMPAIGN_STATUS,
    A2P_CAMPAIGN_FIELDS.DATE_CREATED,
    A2P_CAMPAIGN_FIELDS.DESCRIPTION,
    A2P_CAMPAIGN_FIELDS.MESSAGING_SERVICE_SID,
    A2P_CAMPAIGN_FIELDS.SID,
    A2P_CAMPAIGN_FIELDS.US_APP_TO_PERSON_USECASE,
  ],

  [BRAND_REGISTRATION]: [
    BRAND_REGISTRATION_FIELDS.SID,
    BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID,
    BRAND_REGISTRATION_FIELDS.BRAND_TYPE,
    BRAND_REGISTRATION_FIELDS.STATUS,
    BRAND_REGISTRATION_FIELDS.BRAND_SCORE,
    BRAND_REGISTRATION_FIELDS.DATE_CREATED,
  ],

  [CUSTOMER_PROFILE]: [
    CUSTOMER_PROFILE_FIELDS.FRIENDLY_NAME,
    CUSTOMER_PROFILE_FIELDS.SID,
    CUSTOMER_PROFILE_FIELDS.EMAIL,
  ],

  [MESSAGING_SERVICE]: [
    MESSAGING_SERVICE_FIELDS.FRIENDLY_NAME,
    MESSAGING_SERVICE_FIELDS.SID,
    MESSAGING_SERVICE_FIELDS.DATE_CREATED,
  ],

  [PHONE_REGISTRATION]: [
    PHONE_REGISTRATION_FIELDS.TOTAL_REGISTERED,
    PHONE_REGISTRATION_FIELDS.TOTAL_UNREGISTERED,
    PHONE_REGISTRATION_FIELDS.TWILIO_LIMIT,
    PHONE_REGISTRATION_FIELDS.CAPACITY,
  ],
};

export const registrationList = [
  CUSTOMER_PROFILE,
  BRAND_REGISTRATION,
  A2P_CAMPAIGN,
  MESSAGING_SERVICE,
  PHONE_REGISTRATION,
];

const RegistrationDisplay = ({ registration, registrationErrors = {} }) => {
  return (
    <div>
      {registrationList.map((registrationType) => {
        if (!registration[registrationType]) {
          return null;
        }

        return (
          <React.Fragment key={registrationType}>
            <RegistrationCard
              registration={registration[registrationType]}
              registrationErrors={registrationErrors[registrationType]}
              registrationFields={registrationFields[registrationType]}
              registrationLabels={REGISTRATION_LABELS[registrationType]}
              registrationTitle={REGISTRATION_TITLES[registrationType]}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

RegistrationDisplay.propTypes = {
  registration: PropTypes.shape({
    [A2P_CAMPAIGN]: PropTypes.shape({
      [A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.CAMPAIGN_STATUS]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.DATE_CREATED]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.DESCRIPTION]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.MESSAGING_SERVICE_SID]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.SID]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.US_APP_TO_PERSON_USECASE]: PropTypes.string,
    }),
    [BRAND_REGISTRATION]: PropTypes.shape({
      [BRAND_REGISTRATION_FIELDS.BRAND_SCORE]: PropTypes.number,
      [BRAND_REGISTRATION_FIELDS.BRAND_TYPE]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.DATE_CREATED]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.SID]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.STATUS]: PropTypes.string,
    }),
    [CUSTOMER_PROFILE]: PropTypes.shape({
      [CUSTOMER_PROFILE_FIELDS.EMAIL]: PropTypes.string,
      [CUSTOMER_PROFILE_FIELDS.FRIENDLY_NAME]: PropTypes.string,
      [CUSTOMER_PROFILE_FIELDS.SID]: PropTypes.string,
    }),
    [MESSAGING_SERVICE]: PropTypes.shape({
      [MESSAGING_SERVICE_FIELDS.DATE_CREATED]: PropTypes.string,
      [MESSAGING_SERVICE_FIELDS.FRIENDLY_NAME]: PropTypes.string,
      [MESSAGING_SERVICE_FIELDS.SID]: PropTypes.string,
    }),
    [PHONE_REGISTRATION]: PropTypes.shape({
      [PHONE_REGISTRATION.CAPACITY]: PropTypes.number,
      [PHONE_REGISTRATION.TOTAL_REGISTERED]: PropTypes.number,
      [PHONE_REGISTRATION.TOTAL_UNREGISTERED]: PropTypes.number,
      [PHONE_REGISTRATION.TWILIO_LIMIT]: PropTypes.number,
    }),
  }),
  registrationErrors: PropTypes.shape({
    [A2P_CAMPAIGN]: PropTypes.shape({
      [A2P_CAMPAIGN_FIELDS.BRAND_REGISTRATION_SID]: PropTypes.string,
      [A2P_CAMPAIGN_FIELDS.STATUS]: PropTypes.string,
    }),
    [BRAND_REGISTRATION]: PropTypes.shape({
      [BRAND_REGISTRATION_FIELDS.BRAND_TYPE]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.CUSTOMER_PROFILE_BUNDLE_SID]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.SID]: PropTypes.string,
      [BRAND_REGISTRATION_FIELDS.STATUS]: PropTypes.string,
    }),
    [CUSTOMER_PROFILE]: PropTypes.shape({
      [CUSTOMER_PROFILE_FIELDS.SID]: PropTypes.string,
    }),
    [MESSAGING_SERVICE]: PropTypes.shape({}),
  }),
};

export default RegistrationDisplay;
