import { useCallback, useEffect, useRef, useState } from 'react';
import { brandContentApi, getContrastingTextColor } from '../brand-content-api';
import {
  getBrandContentChanges,
  getHasBrandContentChanged,
  setBrandContentDefaults,
} from './utils';
import { NotificationService } from '../../../../Components/Notifications';

export const SAVED_NOTIFICATION = 'Saved!';

export const usePropertyBrandContent = ({
  closeDrawer,
  propertyList,
  reloadBrandContent,
  setErrorMessage,
}) => {
  const [brandContent, setBrandContent] = useState(null);
  const [logoUrls, setLogoUrls] = useState(null);
  const brandContentRef = useRef({});

  const isBatchUpdate = propertyList.length > 1;

  const [{ propertyId: firstPropertyId }] = propertyList;

  const fetchBrandContentById = useCallback(async () => {
    try {
      const brandContent = await brandContentApi.getBrandContentById(
        firstPropertyId
      );

      brandContentRef.current = brandContent;
      setBrandContent(setBrandContentDefaults(brandContent));
    } catch (e) {
      setErrorMessage(e.message);
    }
  }, [setErrorMessage, firstPropertyId]);

  const fetchBrandContentByIds = useCallback(async () => {
    const propertyIds = propertyList.map((property) => property.propertyId);
    try {
      const brandContent = await brandContentApi.getBrandContentByIds(
        propertyIds
      );

      brandContentRef.current = brandContent;
      setBrandContent(setBrandContentDefaults(brandContent));
    } catch (e) {
      setErrorMessage(e.message);
    }
  }, [propertyList, setErrorMessage]);

  useEffect(() => {
    isBatchUpdate ? fetchBrandContentByIds() : fetchBrandContentById();
  }, [fetchBrandContentById, fetchBrandContentByIds, isBatchUpdate]);

  const updateBrandContent = (contentUpdate) => {
    setBrandContent((prevState) => ({
      ...prevState,
      ...contentUpdate,
    }));
  };

  const updateBrandColor = (brandColor) => {
    const contrastingTextColor = getContrastingTextColor(brandColor);

    updateBrandContent({
      brandColor,
      contrastingTextColor,
      isBrandColorMixed: false,
    });
  };

  const onLogoUpload = ({ thumbUrl, url }) => {
    setLogoUrls({ thumbUrl, url });
    updateBrandContent({ logoUrl: url });
  };

  const removeLogo = () => {
    const isRevertingLogo = Boolean(logoUrls);
    const originalLogoUrl = brandContentRef.current.logoUrl;

    const logoUrl = isRevertingLogo ? originalLogoUrl : '';
    const updatedLogoUrls = isRevertingLogo ? null : {};

    setLogoUrls(updatedLogoUrls);
    updateBrandContent({ logoUrl });
  };

  const hasBrandContentChanged = getHasBrandContentChanged(
    brandContent || {},
    brandContentRef.current
  );

  const saveBrandContent = async () => {
    const propertyIds = propertyList.map((property) => property.propertyId);
    const brandContentChanges = getBrandContentChanges(
      brandContent || {},
      brandContentRef.current
    );

    const payload = {
      propertyIds,
      ...brandContentChanges,
      ...(logoUrls && { logoUrls }),
    };

    try {
      await brandContentApi.updateBrandContent(payload);
      NotificationService.notify(SAVED_NOTIFICATION);
      reloadBrandContent();
      closeDrawer();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  return {
    brandContent: brandContent || {},
    hasBrandContentChanged,
    isBatchUpdate,
    onLogoUpload,
    removeLogo,
    saveBrandContent,
    updateBrandColor,
    updateBrandContent,
  };
};
