import React, { useEffect, useState } from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PropTypes, { object } from 'prop-types';
import DataTable from 'material-table';
import {
  LoadingOverlay,
  makeStyles,
  Select,
  Switch,
  ThemeProvider,
  Tooltip,
} from '@knockrentals/knock-shared-web';
import { Toaster } from '@knockrentals/knock-react';

import { FilterProperty } from './Components/FilterBoxes';
import {
  getVirtualAgents,
  getVoicesList,
  updateVirtualAgent,
} from '../VirtualAgentAPI';
import { editAiEmailEnabled } from '../aiEmailAPI';
import { getOfferTimes } from '../VirtualAgentLookupTables';
import TitleWithTooltip from './Components/TitleWithTooltip';

const useStyles = makeStyles({
  ringTimeSelect: {
    border: 0,
    padding: 0,
    fontWeight: 550,
    textAlign: 'center',

    '& #ring-time-dropdown': {
      paddingRight: '0',
    },
  },
  callRoutingSelect: {
    border: 0,
    borderBottom: '1px solid #DEDFE6',
    backgroundColor: '#FAFAFA',
    width: '150px',
    paddingRight: '15px',
  },
  filterAndEditContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
});

export const FIELDS = {
  CALL_ROUTING: 'call_routing',
  OFFER_TIMES: 'offer_time',
  ANSWER_VERIFICATION: 'is_answer_verification',
  IS_GEN_AI_VOICE_ENABLED: 'is_gen_ai_voice_enabled',
  RPCC_AGENT_ROLLOVER: 'rpcc_agent_rollover',
  IS_CHAT_ENABLED: 'is_chat_enabled',
  IS_SMS_ENABLED: 'is_sms_enabled',
  IS_AI_EMAIL_ENABLED: 'ai_email_enabled',
  SCHEDULE_TOUR_VA_ENABLED: 'schedule_tour_va_enabled',
  PNA_VA_ENABLED: 'pna_va_enabled',
  VOICE_ID: 'voice_id',
};

const TOOL_TIPS = {
  [FIELDS.CALL_ROUTING]: 'Setting to All Calls will route all incoming prospect calls to AI.  Setting to Missed Calls will route only missed prospect calls to AI',
  [FIELDS.VOICE_ID]: 'Options for voice used for AI',
  [FIELDS.OFFER_TIMES]: 'Only applies to Missed Calls.  The length of time a call will ring to the property before transferring to AI',
  [FIELDS.ANSWER_VERIFICATION]: 'Only applies to Missed Calls. Used only as necessary depending on the property’s telephony setup',
  [FIELDS.IS_CHAT_ENABLED]: 'Activates AI on existing Knockbot widget',
  [FIELDS.IS_SMS_ENABLED]: 'Activates AI SMS',
  [FIELDS.IS_AI_EMAIL_ENABLED]: 'Activates AI Email',
  [FIELDS.PNA_VA_ENABLED]: 'Controls whether AI will quote Pricing & Availability.  If disabled, VA will collect info and make a note for the site staff to contact the prospect',
  [FIELDS.SCHEDULE_TOUR_VA_ENABLED]: 'Controls whether AI will send Schedule Tour link.  If disabled, VA will collect info and make a note for the site staff to contact the prospect',
}

export const OFFER_TIMES = Array(25)
  .fill(0)
  .map((_, i) => ({ label: `${i + 6} seconds`, value: i + 6 }));

export const CALL_ROUTING = [
  {
    label: 'All Calls',
    value: 'ALL',
  },
  {
    label: 'Missed Calls',
    value: 'MISSED_CALLS',
  },
  {
    label: 'Off',
    value: 'OFF',
  },
];

export const API_ERROR = 'Error updating virtual agent';

const VirtualAgentConfigSettings = ({
  properties,
  isInternalMode,
  companyId,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [virtualAgents, setVirtualAgents] = useState([]);
  const [fetchedVirtualAgents, setFetchedVirtualAgents] = useState(false);
  const [voices, setVoices] = useState([]);
  const [fetchedVoices, setFetchedVoices] = useState(false);

  const [filteredVirtualAgents, setFilteredVirtualAgents] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState(
    properties.map(({ propertyId }) => propertyId)
  );
  const [filters, setFilters] = useState({});

  const tableOptions = {
    actionsColumnIndex: -1,
    draggable: false,
    grouping: false,
    headerStyle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '32px',
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    padding: 'dense',
    paging: false,
    rowStyle: {
      fontSize: '14px',
      fontWeight: 400,
    },
    search: false,
    sorting: false,
    toolbar: false,
    maxBodyHeight: '65vh',
  };

  useEffect(() => {
    const getVoicesData = async () => {
      const response = await getVoicesList();
      const voices = response.results ?? [];
      let voicesList = [];
      voices.forEach((voice) => {
        voicesList.push({
          label: voice.display_name,
          value: voice.id,
        });
      });
      setVoices(voicesList);
    };

    if (!fetchedVoices) {
      setIsLoading(true);
      getVoicesData()
        .catch(() => {
          Toaster.showToast(
            'Error fetching voices',
            2000,
            Toaster.ToastClasses.error
          );
        })
        .finally(() => {
          setFetchedVoices(true);
          setIsLoading(false);
        });
    }

    const getVirtualAgentsData = async () => {
      const propertyIds = properties.map(({ propertyId }) => propertyId);
      const virtualAgentsResponse = await getVirtualAgents(companyId, 1, 1000);
      const virtualAgents = (virtualAgentsResponse.results || [])
        .filter((virtualAgent) =>
          propertyIds.includes(virtualAgent.property_id)
        )
        .map((virtualAgent) => {
          const property = properties.find(
            (property) => property.propertyId === virtualAgent.property_id
          );
          return {
            ...virtualAgent,
            propertyName: property.propertyName,
            ai_email_enabled: property.aiEmailEnabled,
            voice_product_enabled: property.voiceProductEnabled,
            chat_product_enabled: property.chatProductEnabled,
            sms_product_enabled: property.smsProductEnabled,
            email_product_enabled: property.emailProductEnabled,
          };
        });

      virtualAgents.sort((a, b) =>
        a.propertyName.localeCompare(b.propertyName)
      );
      setVirtualAgents(virtualAgents);
      setFilteredVirtualAgents(virtualAgents);
    };

    if (!fetchedVirtualAgents && properties.length) {
      setIsLoading(true);
      getVirtualAgentsData()
        .catch(() => {
          Toaster.showToast(
            'Error fetching virtual agent data',
            2000,
            Toaster.ToastClasses.error
          );
        })
        .finally(() => {
          setFetchedVirtualAgents(true);
          setIsLoading(false);
        });
    }
  }, [fetchedVirtualAgents, fetchedVoices]);

  const applyFilters = (currentFilters, currentSelectedProperties) => {
    const filteredVirtualAgents = virtualAgents.filter((virtualAgent) => {
      const isRowValid = Object.keys(currentFilters).every((key) => {
        if (currentFilters[key] === null) {
          return true;
        } else if (Array.isArray(currentFilters[key])) {
          return currentFilters[key].includes(virtualAgent[key]);
        } else if (key === 'ai_email_enabled') {
          return (
            (currentFilters[key] && virtualAgent[key] === 'ON') ||
            (!currentFilters[key] && virtualAgent[key] !== 'ON')
          );
        } else {
          return virtualAgent[key] === currentFilters[key];
        }
      });

      const isSelected = currentSelectedProperties.length
        ? currentSelectedProperties.includes(virtualAgent.property_id)
        : true;

      return isRowValid && isSelected;
    });

    setFilteredVirtualAgents(filteredVirtualAgents);
  };

  const handlePropertySelect = (properties) => {
    applyFilters(filters, properties);
    setSelectedProperties(properties);
  };

  const isCallRoutingMissed = (field) => {
    return field === 'MISSED_CALLS';
  };

  const saveAiEmail = async (rowData, value) => {
    const aiEmailValue = value ? 'ON' : 'SETUP';
    const payload = [
      {
        property_id: rowData.property_id,
        company_id: companyId,
        [FIELDS.IS_AI_EMAIL_ENABLED]: aiEmailValue,
      },
    ];

    try {
      await editAiEmailEnabled(payload);
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

      const property = properties.find(
        (property) => property.propertyId === rowData.property_id
      );
      property.aiEmailEnabled = aiEmailValue;
      rowData[FIELDS.IS_AI_EMAIL_ENABLED] = aiEmailValue;

      const existingIdx = filteredVirtualAgents.findIndex(
        (va) => va.id === rowData.id
      );
      setFetchedVirtualAgents([
        ...filteredVirtualAgents.slice(0, existingIdx),
        rowData,
        ...filteredVirtualAgents.slice(
          existingIdx + 1,
          filteredVirtualAgents.length
        ),
      ]);
    } catch (err) {
      console.error(err);
      Toaster.showToast(API_ERROR, 2000, Toaster.ToastClasses.error);
    }
  };

  const saveVaData = async (rowData, field, value) => {
    const payload = {
      virtualAgentId: rowData.id,
      propertyId: rowData.property_id,
      field,
      value,
    };

    try {
      await updateVirtualAgent(payload);
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      rowData[field] = value;

      const existingIdx = filteredVirtualAgents.findIndex(
        (va) => va.id === rowData.id
      );
      setFetchedVirtualAgents([
        ...filteredVirtualAgents.slice(0, existingIdx),
        rowData,
        ...filteredVirtualAgents.slice(
          existingIdx + 1,
          filteredVirtualAgents.length
        ),
      ]);
    } catch (err) {
      console.error(err);
      Toaster.showToast(API_ERROR, 2000, Toaster.ToastClasses.error);
    }
  };

  const getColumns = (classes) => {
    const tableArray = [
      {
        title: 'Properties',
        width: '120px',
        render: (rowData) => rowData.propertyName,
        cellStyle: {
          position: 'sticky',
          left: 0,
          zIndex: 3,
          backgroundColor: '#fff',
        },
        headerStyle: {
          left: '0px',
          zIndex: 4,
        },
      },
      {
        title: TitleWithTooltip('Call Routing'),
        tooltip: TOOL_TIPS[FIELDS.CALL_ROUTING],
        field: FIELDS.CALL_ROUTING,
        width: '150px',
        render: (rowData) => {
          return (
            <Tooltip title={rowData.voice_product_enabled ? "" : "AI Voice Product not active"}>
              <span>
                <Select
                  noForm
                  className={classes.callRoutingSelect}
                  disabled={!rowData.voice_product_enabled}
                  options={CALL_ROUTING}
                  defaultValue={rowData[FIELDS.CALL_ROUTING]}
                  onSelectChange={(value) => {
                    saveVaData(rowData, FIELDS.CALL_ROUTING, value);
                  }}
                  IconComponent={() => <KeyboardArrowDown />}
                />
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: TitleWithTooltip('Agent Voice'),
        tooltip: TOOL_TIPS[FIELDS.VOICE_ID],
        field: FIELDS.VOICE_ID,
        width: '150px',
        render: (rowData) => {
          return (
            <Select
              noForm
              className={classes.callRoutingSelect}
              options={voices}
              defaultValue={rowData[FIELDS.VOICE_ID]}
              onSelectChange={(value) => {
                saveVaData(rowData, FIELDS.VOICE_ID, value);
              }}
              IconComponent={() => <KeyboardArrowDown />}
            />
          );
        },
      },
      {
        title: TitleWithTooltip('Ring Time'),
        tooltip: TOOL_TIPS[FIELDS.OFFER_TIMES],
        field: FIELDS.OFFER_TIMES,
        width: '150px',
        render: (rowData) => {
          return (
            <Select
              noForm
              id={'ring-time-dropdown'}
              className={classes.ringTimeSelect}
              options={getOfferTimes()}
              defaultValue={rowData[FIELDS.OFFER_TIMES]}
              value={rowData[FIELDS.OFFER_TIMES]}
              onSelectChange={(value) => {
                saveVaData(rowData, FIELDS.OFFER_TIMES, value);
              }}
              IconComponent={() => <ArrowDropDown />}
            />
          );
        },
      },
    ];

    if (isInternalMode) {
      tableArray.push(
        {
          title: TitleWithTooltip('Answer Verification'),
          tooltip: TOOL_TIPS[FIELDS.ANSWER_VERIFICATION],
          field: FIELDS.ANSWER_VERIFICATION,
          width: '100px',
          render: (rowData) => {
            return (
              <Tooltip title={!isCallRoutingMissed(rowData[FIELDS.CALL_ROUTING]) ? "Call Routing is not set to 'Missed'" : ""}>
                <span>
                  <Switch
                    color="primary"
                    checked={!!rowData.is_answer_verification}
                    disabled={!isCallRoutingMissed(rowData[FIELDS.CALL_ROUTING])}
                    onChange={(event) =>
                      saveVaData(
                        rowData,
                        FIELDS.ANSWER_VERIFICATION,
                        event.target.checked
                      )
                    }
                  />
                </span>
              </Tooltip>
            );
          },
        },
        {
          title: TitleWithTooltip('AI Chat'),
          tooltip: TOOL_TIPS[FIELDS.IS_CHAT_ENABLED],
          field: FIELDS.IS_CHAT_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Tooltip title={rowData.chat_product_enabled ? "" : "AI Chat Product not active"}>
                <span>
                  <Switch
                    color="primary"
                    checked={!!rowData.is_chat_enabled}
                    disabled={!rowData.chat_product_enabled}
                    onChange={(event) =>
                      saveVaData(
                        rowData,
                        FIELDS.IS_CHAT_ENABLED,
                        event.target.checked
                      )
                    }
                  />
                </span>
              </Tooltip>
            );
          },
        },
        {
          title: TitleWithTooltip('AI SMS'),
          tooltip: TOOL_TIPS[FIELDS.IS_SMS_ENABLED],
          field: FIELDS.IS_SMS_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Tooltip title={rowData.sms_product_enabled ? "" : "AI SMS Product not active"}>
                <span>
                  <Switch
                    color="primary"
                    checked={!!rowData.is_sms_enabled}
                    disabled={!rowData.sms_product_enabled}
                    onChange={(event) =>
                      saveVaData(
                        rowData,
                        FIELDS.IS_SMS_ENABLED,
                        event.target.checked
                      )
                    }
                  />
                </span>
              </Tooltip>
            );
          },
        },
        {
          title: TitleWithTooltip('AI Email'),
          tooltip: TOOL_TIPS[FIELDS.IS_AI_EMAIL_ENABLED],
          field: FIELDS.IS_AI_EMAIL_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Tooltip title={rowData.email_product_enabled === 'OFF' ? "AI Email Product not active" : ""}>
                <span>
                  <Switch
                    color="primary"
                    checked={rowData[FIELDS.IS_AI_EMAIL_ENABLED] === 'ON'}
                    disabled={rowData.email_product_enabled === 'OFF'}
                    onChange={(event) => saveAiEmail(rowData, event.target.checked)}
                  />
                </span>
              </Tooltip>
            );
          },
        },
        {
          title: TitleWithTooltip('P&A Flow'),
          tooltip: TOOL_TIPS[FIELDS.PNA_VA_ENABLED],
          field: FIELDS.PNA_VA_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.pna_va_enabled}
                onChange={(event) =>
                  saveVaData(
                    rowData,
                    FIELDS.PNA_VA_ENABLED,
                    event.target.checked
                  )
                }
              />
            );
          },
        },
        {
          title: TitleWithTooltip('Schedule Tour Flow'),
          tooltip: TOOL_TIPS[ FIELDS.SCHEDULE_TOUR_VA_ENABLED],
          field: FIELDS.SCHEDULE_TOUR_VA_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.schedule_tour_va_enabled}
                onChange={(event) =>
                  saveVaData(
                    rowData,
                    FIELDS.SCHEDULE_TOUR_VA_ENABLED,
                    event.target.checked
                  )
                }
              />
            );
          },
        }
      );
    }

    return tableArray;
  };

  return (
    <ThemeProvider>
      <div className={classes.filterAndEditContainer}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <FilterProperty
            properties={properties.map((property) => ({
              id: property.propertyId,
              name: property.propertyName,
              selected: selectedProperties.includes(property.propertyId),
            }))}
            onPropertySelect={handlePropertySelect}
          />
        </div>
      </div>
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />
      <DataTable
        className="scheduling-grid-data-table"
        columns={getColumns(classes)}
        data={filteredVirtualAgents}
        options={tableOptions}
      />
    </ThemeProvider>
  );
};

VirtualAgentConfigSettings.propTypes = {
  properties: PropTypes.arrayOf(object),
  companyId: PropTypes.number,
  isInternalMode: PropTypes.bool,
};

export default VirtualAgentConfigSettings;
