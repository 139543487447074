import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles({
  registrationCard: {
    marginTop: '24px',
  },
  registrationTable: {
    '& td': {
      minWidth: 'unset',
      maxWidth: 'unset',
      padding: '6px',
      border: 0,
      borderRadius: '4px',
    },
    '& td:first-of-type': {
      backgroundColor: '#ffffff',
      fontWeight: '600',
      width: '140px',
      textAlign: 'right',
    },
    '& tr:nth-child(odd)': {
      backgroundColor: '#f8f8f8',
    },
  },
});

const RegistrationCard = ({
  registration,
  registrationErrors = {},
  registrationFields,
  registrationLabels,
  registrationTitle,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.registrationCard} elevation={1}>
        <CardHeader title={registrationTitle} />

        <CardContent>
          <Table
            className={classes.registrationTable}
            title={registrationTitle}
          >
            <TableBody>
              {registrationFields.map((field) => {
                const errorMessage = registrationErrors[field];

                return (
                  <TableRow key={registrationLabels[field]}>
                    <TableCell>{registrationLabels[field]}:</TableCell>

                    <TableCell>
                      {registration[field]}
                      <br />
                      {errorMessage && (
                        <Text color="error" variant="caption">
                          {errorMessage}
                        </Text>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

RegistrationCard.propTypes = {
  registration: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  registrationErrors: PropTypes.objectOf(PropTypes.string),
  registrationFields: PropTypes.arrayOf(PropTypes.string),
  registrationLabels: PropTypes.objectOf(PropTypes.string),
  registrationTitle: PropTypes.string,
};

export default RegistrationCard;
